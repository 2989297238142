import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { listSubject } from "../../redux/subject/action";
import { showClassroom, updateClassroom, removeChapter, addChapter } from "../../redux/classroom/action";
import { listClassroomGroup } from "../../redux/classroomgroup/action";
import { Radio } from "antd";
import { listStudent } from "../../redux/student/action";
import { uploadImage, listCategory, updateMetaDataCategory } from "../../redux/category/action";
import { listChapter, listChapterByClassRoom, updateMetaDataChapter } from "../../redux/chapter/action";
import { Editor } from "@tinymce/tinymce-react";
import { DatePicker } from "antd";
import moment from "moment";
import ModalAttachedBook from "../book/ModalAttachedBook";
import ModalBookRelate from "../book/ModalBookRelate";
import ModalClassroomRelate from "./Components/ModalClassroomRelate";
import baseHelpers from "../../helpers/BaseHelpers";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { map } from "lodash";

import ChapterItemList from "../lesson/Components/ChapterItemList";

import ModalEditLesson from "../lesson/Components/ModalEditLesson";

class ClassroomEdit extends Component {
  constructor(props) {
    super();
    this.state = {
      code: "",
      name: "",
      subject_id: "",
      teacher_id: "",
      group_id: "",
      teacher: "",
      room: "",
      note: "",
      description: "",
      video_intro: "",
      content: "",
      files: [],
      next_classrooms: null,
      hp_day: "",
      hp_1month_day: "",
      hp_3month_day: "",
      hp_6month_day: "",
      hp_12month_day: "",
      is_cadup: false,
      is_auto_diff_day: false,
      is_online: false,
      is_featured: false,
      status: false,
      extra_number_student: 0,
      link_fb_page: "",
      link_fb_group: "",
      cart_category_id: "",
      promotion: {
        from_date: null,
        to_date: null,
        type: "BY_DATE_RANGE",
        hour: 0
      },
      level: null,
      quantity: 0,
      chapters: [],
      ordering: 0,
      price: "",
      origin_price: "",
      listBookAttacheds: [],
      listBookRelates: [],
      listClassroomRelates: [],
      avtPreview: "",
      isOpen: false,
      categories: [],
      selectedChapterId: null,
      selectedBookAttachedIDS: [],
      selectedBookRelateIDS: [],
      selectedClassroomRelateIDs: [],

      // -----------------chapter by lesson------------------------------
      selectedChapters: [],
      selectedCateId: null,
      is_open: false,
      search_chapter: "",
    };
  }

  async componentDidMount() {
    let params = {
      limit: 999,
      is_delete: false,
    };

    let _promotion = {};


    await this.props.showClassroom(this.props.match.params.id);

    await this.props.listSubject(params);

    await this.props.listChapterByClassRoom(this.props.match.params.id);

    params = {
      user_group: "TEACHER",
    };
    if (this.props.classroom) {
      params.subject_id = this.props.classroom.subject.id;
      await this.props.listStudent(params);

      params = {
        keyword: "",
        subject_id: null,
        limit: 100,
      };
      await this.props.listChapter(params);
      await this.props.listClassroomGroup(params);

      var {
        code,
        name,
        teacher,
        room,
        note,
        description,
        content,
        hp_day,
        hp_1month_day,
        hp_3month_day,
        hp_6month_day,
        hp_12month_day,
        is_cadup,
        is_auto_diff_day,
        is_online,
        is_featured,
        price,
        origin_price,
        video_intro,
        files,
        status,
        extra_number_student,
        link_fb_group,
        link_fb_page,
        cart_category_id,
        promotion,
        ordering,
        level,
        quantity,
        image,
        book_attached,
        book_relates,
        classroom_relates
      } = this.props.classroom;

      if (!promotion) {
        _promotion = {
          from_date: null,
          to_date: null,
          type: "BY_DATE_RANGE",
          hour: 0
        }
      } else if (promotion && !promotion.type) {
        _promotion = {
          ...promotion,
          type: "BY_DATE_RANGE"
        }
      } else {
        _promotion = {
          ...promotion
        }
      }


      this.setState({
        code,
        name,
        subject_id: this.props.classroom.subject.id,
        subjectID: this.props.classroom.subject.id,
        group_id: this.props.classroom.group
          ? this.props.classroom.group.id
          : "",
        teacher_id: this.props.classroom.teacher_id,
        teacher,
        room,
        note,
        description,
        content,
        files,
        price,
        video_intro,
        origin_price,
        hp_day: hp_day || "",
        hp_1month_day: hp_1month_day || "",
        hp_3month_day: hp_3month_day || "",
        hp_6month_day: hp_6month_day || "",
        hp_12month_day: hp_12month_day || "",
        is_cadup: is_cadup || false,
        is_auto_diff_day: is_auto_diff_day || false,
        is_online: is_online || false,
        is_featured: is_featured || false,
        status,
        link_fb_group,
        link_fb_page,
        extra_number_student,
        cart_category_id,
        ordering: ordering || 0,
        promotion: _promotion,
        level,
        quantity,
        avtPreview: image,
        selectedBookAttachedIDS: book_attached ? book_attached : [],
        selectedBookRelateIDS: book_relates ? book_relates : [],
        selectedClassroomRelateIDs: classroom_relates ? classroom_relates : [],
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.chapters && nextProps.chapters != this.props.chapters) {

      this.setState({
        chapters: nextProps.chapters,
      });
    }
    if (this.props.categories !== nextProps.categories) {
      this.setState({
        categories: nextProps.categories
      })
    }
    if (this.props.listSelectedchapters != nextProps.listSelectedchapters) {
      this.setState({
        selectedChapters: nextProps.listSelectedchapters
      })
    }
  }

  _onChangeNextClassroom = async (e) => {
    console.log(123, e);
  };

  _onChange = async (e) => {
    var name = e.target.name;
    var value = e.target.value;
    let checked = e.target.checked;
    let avtPreview = "";

    if (name === "is_featured" || name === "status") {
      value = checked;
    }

    if (name === "files") {

      value = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = () => {
          avtPreview = reader.result;
          resolve(reader.result);
        };
        reader.onerror = (error) => reject(error);
      });
      value = [value];
      this.setState({
        [name]: value,
        avtPreview: avtPreview
      });
    } else {
      this.setState({
        [name]: value,
      });
    }


    if (name === "subject_id") {
      let params = {};

      if (value) {
        params = {
          subject_id: value,
          limit: 100,
        };
        await this.props.listChapter(params);
        await this.props.listClassroomGroup(params);


        params = {
          user_group: "TEACHER",
          subject_id: value,
          limit: 100,
        };
        await this.props.listStudent(params);

        if (this.props.chapters) {
          this.setState({
            chapters: this.props.chapters
          })
        }
      }
    }
  };


  _onChangeTypePromotion = async (e) => {

    let { name, value } = e.target;

    this.setState({
      promotion: {
        ...this.state.promotion,
        [name]: value
      }
    })


  }

  handleSubmit = async (e) => {
    e.preventDefault();

    let { promotion, listBookAttacheds, selectedChapters, listBookRelates, listClassroomRelates } = this.state;

    const data = {
      id: this.props.match.params.id,
      name: this.state.name,
      code: this.state.code,
      subject_id: this.state.subject_id,
      group_id: this.state.group_id,
      teacher_id: this.state.teacher_id,
      teacher: this.state.teacher,
      room: this.state.room,
      note: this.state.note,
      video_intro: this.state.video_intro,
      content: this.state.content,
      description: this.state.description,
      hp_day: this.state.hp_day,
      files: this.state.files,
      price: this.state.price,
      origin_price: this.state.origin_price,
      hp_1month_day: this.state.hp_1month_day,
      hp_3month_day: this.state.hp_3month_day,
      hp_6month_day: this.state.hp_6month_day,
      hp_12month_day: this.state.hp_12month_day,
      is_cadup: this.state.is_cadup,
      is_auto_diff_day: this.state.is_auto_diff_day,
      is_online: this.state.is_online,
      is_featured: this.state.is_featured,
      status: this.state.status,
      extra_number_student: this.state.extra_number_student,
      link_fb_group: this.state.link_fb_group,
      link_fb_page: this.state.link_fb_page,
      cart_category_id: this.state.cart_category_id,
      // promotion: promotion && promotion.to_date || promotion && promotion.from_date ? promotion : null,
      promotion: promotion ? promotion : null,
      ordering: this.state.ordering,
      level: this.state.level,
      quantity: this.state.quantity,
      book_attached: listBookAttacheds ? listBookAttacheds.map(item => item.id) : [],
      book_relates: listBookRelates ? listBookRelates.map(item => item.id) : [],
      classroom_relates: listClassroomRelates ? listClassroomRelates.map(item => item.id) : [],

    };

    let chapterDatas = [];
    if (selectedChapters && selectedChapters.length > 0) {
      selectedChapters.map((item, index) => {
        chapterDatas.push({
          id: item._id,
          name: item.name,
          ordering: index + 1,
        })
      })

      data.chapters = chapterDatas;
      // data.chapter_delete_ids = chapterDatas.map(item => item.id);
    }

    await this.props.updateClassroom(data);
    // if (this.props.redirect === true) {
    //   await this.props.history.push("/classroom");
    // }
  };

  fetchSubjectRows() {
    if (this.props.subjects instanceof Array) {
      return this.props.subjects.map((obj, i) => {
        return (
          <option value={obj._id} key={obj._id.toString()}>
            {obj.name}
          </option>
        );
      });
    }
  }
  fetchTeacherRows() {
    if (this.props.students instanceof Array) {
      return this.props.students.map((obj, i) => {
        return (
          <option value={obj._id} key={obj._id.toString()}>
            {obj.fullname}
          </option>
        );
      });
    }
  }

  fetchGroupRows() {
    if (
      this.props.classroomGroups &&
      this.props.classroomGroups instanceof Array
    ) {
      return this.props.classroomGroups.map((obj, i) => {
        return (
          <option value={obj._id} key={obj._id.toString()}>
            {obj.name}
          </option>
        );
      });
    }
  }

  fetchCartCategory() {
    if (this.props.cartCategories instanceof Array) {
      return this.props.cartCategories.map((obj, i) => {
        return (
          <option value={obj._id} key={obj._id.toString()}>
            {obj.name}
          </option>
        );
      });
    }
  }

  _handleEditorContentChange = (value, editor) => {
    this.setState({ content: value });
  };
  _handleEditorDescriptionChange = (value, editor) => {
    this.setState({ description: value });
  };

  _uploadImageCallBack = async (blobInfo, success, failure) => {
    let file = blobInfo.blob();
    const data = new FormData();
    data.append("files", file);

    await this.props.uploadImage(data);

    if (this.props.image != null) {
      success(this.props.image);
    } else {
      failure("Upload image fail");
    }
  };

  changeDateStart = (date, dateString) => {
    if (date !== null) {
      this.setState({
        promotion: {
          ...this.state.promotion,
          from_date: date.format("YYYY/MM/DD HH:mm"),
        }
      });
    } else {
      this.setState({
        promotion: {
          ...this.state.promotion,
          from_date: null,
        }
      });
    }
  };

  changeDateEnd = (date, dateString) => {
    if (date !== null) {
      this.setState({
        promotion: {
          ...this.state.promotion,
          to_date: date.format("YYYY/MM/DD HH:mm"),
        }
      });
    } else {
      this.setState({
        promotion: {
          ...this.state.promotion,
          to_date: null,
        }
      });
    }
  };

  // ------------------------New code-----------------------------
  handleUploadImage = () => {
    document.getElementById("input-upload-image").click();
  }

  remoAvatar = () => {
    document.getElementById("input-upload-image").value = "";
    this.setState({
      files: [],
      avtPreview: ""
    })
  }

  handleAddBookAttached = async (data) => {
    let dataList = [...this.state.listBookAttacheds];
    if (data) {
      dataList.push(data);
    }


    await this.setState({
      listBookAttacheds: dataList
    })

  }

  handleAddBookRelate = async (data) => {
    let dataList = [...this.state.listBookRelates];
    if (data) {
      dataList.push(data);
    }


    await this.setState({
      listBookRelates: dataList
    })

  }


  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  onDragEndBookAttached = async (result) => {
    if (!result.destination) {
      return;
    }

    const items = this.reorder(
      this.state.listBookAttacheds,
      result.source.index,
      result.destination.index
    );

    await this.setState({
      listBookAttacheds: items,
    });
  }

  onDragEndBookRelate = async (result) => {
    if (!result.destination) {
      return;
    }

    const items = this.reorder(
      this.state.listBookRelates,
      result.source.index,
      result.destination.index
    );

    await this.setState({
      listBookRelates: items,
    });
  }

  handleAddSelectedBookAttched = async (data) => {
    if (data) {
      await this.setState({
        listBookAttacheds: data
      })
    }
  }

  handleAddSelectedBookRelate = async (data) => {
    if (data) {
      await this.setState({
        listBookRelates: data
      })
    }
  }

  getListCategory = async (id) => {
    let data = {};
    if (id) {
      data = {
        chapter_id: id,
        limit: 100,
        is_sort_ordering: true,
      };
    }
    await this.props.listCategory(data);

    await this.setState({
      selectedChapterId: id,
      isOpen: !this.state.isOpen
    })
  }


  handleSetIsNotOpenBlock = () => {
    this.setState({
      isOpen: false
    })
  }

  handleRemoveChapter = async (id) => {
    let data = {
      chapter_id: id,
      classroom_id: this.props.match.params.id
    }

    if (data) {
      await this.props.removeChapter(data);
      await this.props.listChapterByClassRoom(this.props.match.params.id);
    }

  }

  fetchListChaptersByClass() {
    if (this.state.selectedChapters instanceof Array) {
      return this.state.selectedChapters.map((object, index) => {
        return (
          <Draggable
            key={index}
            draggableId={"" + index}
            index={index}
          >
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
              >
                <ChapterItemList
                  obj={object}
                  key={index}
                  isOpen={this.state.isOpen}
                  index={index}
                  categories={this.state.categories}
                  selectedChapterId={this.state.selectedChapterId}
                  handleSetIsNotOpenBlock={this.handleSetIsNotOpenBlock}
                  getListCategory={this.getListCategory}
                  onDragEndCategory={this.onDragEndCategory}
                  isNotShowAction={true}
                  isBtnAddChapter={false}
                  handleRemoveChapter={this.handleRemoveChapter}
                  handleSetCateId={this.handleSetCateId}
                  provided={provided}
                  // providedRef={provided.innerRef}
                  isDragChapter={true}
                />
              </div>
            )}
          </Draggable>
        );
      });
    }
  }

  // fetchListChaptersByClass() {
  //   if (this.state.selectedChapters instanceof Array) {
  //     return this.state.selectedChapters.map((object, index) => {
  //       return (

  //         <ChapterItemList
  //           obj={object}
  //           key={index}
  //           isOpen={this.state.isOpen}
  //           index={index}
  //           categories={this.state.categories}
  //           selectedChapterId={this.state.selectedChapterId}
  //           handleSetIsNotOpenBlock={this.handleSetIsNotOpenBlock}
  //           getListCategory={this.getListCategory}
  //           onDragEndCategory={this.onDragEndCategory}
  //           isNotShowAction={true}
  //           isBtnAddChapter={false}
  //           handleRemoveChapter={this.handleRemoveChapter}
  //           handleSetCateId={this.handleSetCateId}
  //         />
  //       );
  //     });
  //   }
  // }

  handleAddChapter = async (data) => {

    // let chapters = [...this.state.selectedChapters];
    // if (data) {
    //   chapters.push(data);
    // }


    // await this.setState({
    //   selectedChapters: chapters
    // })
    let payload = {
      chapter_id: data._id,
      classroom_id: this.props.match.params.id
    }

    if (payload) {
      await this.props.addChapter(payload);
      await this.props.listChapterByClassRoom(this.props.match.params.id);
    }
  }

  fetchListChapters() {
    let { selectedChapters, search_chapter } = this.state;
    let finIndex = -1;
    if (this.state.chapters instanceof Array) {
      return this.state.chapters.map((object, index) => {
        finIndex = selectedChapters.findIndex(item => item._id == object._id);

        // if (finIndex == -1 && object.name.toLowerCase().includes(search_chapter.toLowerCase())) {
        if (finIndex == -1) {
          return (
            <ChapterItemList
              obj={object}
              key={index}
              isOpen={this.state.isOpen}
              index={index}
              categories={this.state.categories}
              selectedChapterId={this.state.selectedChapterId}
              handleSetIsNotOpenBlock={this.handleSetIsNotOpenBlock}
              getListCategory={this.getListCategory}
              onDragEndCategory={this.onDragEndCategory}
              isNotShowAction={true}
              isBtnAddChapter={true}
              handleAddChapter={this.handleAddChapter}
              handleSetCateId={this.handleSetCateId}
            />
          );
        }
      });
    }
  }


  handleSetCateId = async (id) => {
    await this.setState({
      selectedCateId: id
    })
  }

  onDragEndCategory = async (result) => {
    if (!result.destination) {
      return;
    }

    let { categories } = this.state;

    let data = [];

    const items = this.reorder(
      categories,
      result.source.index,
      result.destination.index
    );

    if (items) {
      map(items, (_item, _index) => {

        let dataItem = {
          ordering: parseInt(_index + 1),
          id: _item._id
        };
        data.push(dataItem);

      })

      if (data && data.length > 0) {
        await this.props.updateMetaDataCategory(data);
      }

      // this.props.handleChangeCategories(items);

      await this.setState({
        categories: items
      })
    }

  }

  onDragEndChapterByClass = async (result) => {
    if (!result.destination) {
      return;
    }

    let { selectedChapters } = this.state;

    let data = [];


    const items = this.reorder(
      selectedChapters,
      result.source.index,
      result.destination.index
    );

    if (items) {
      map(items, (_item, _index) => {

        let dataItem = {
          ordering: parseInt(_index + 1),
          id: _item._id
        };
        data.push(dataItem);

      })

      if (data && data.length > 0) {
        await this.props.updateMetaDataChapter(data);
      }

      await this.setState({
        selectedChapters: items,
        isOpen: false
      })
    }

  }

  handleRemoveBookAttached = async (item) => {
    let dataRemove = [];
    let { listBookAttacheds } = this.state;



    if (listBookAttacheds) {
      dataRemove = listBookAttacheds.filter(value => value.id !== item.id);
    }

    this.setState({
      listBookAttacheds: dataRemove
    })
  }

  handleRemoveBookRelate = async (item) => {
    let dataRemove = [];
    let { listBookRelates } = this.state;



    if (listBookRelates) {
      dataRemove = listBookRelates.filter(value => value.id !== item.id);
    }

    this.setState({
      listBookRelates: dataRemove
    })
  }

  handleAddClassroomRelate = async (data) => {
    let dataList = [...this.state.listClassroomRelates];
    if (data) {
      dataList.push(data);
    }


    await this.setState({
      listClassroomRelates: dataList
    })

  }

  handleAddSelectedClassroomRelate = async (data) => {

    if (data) {
      await this.setState({
        listClassroomRelates: data
      })
    }

  }

  onDragEndClassroomRelate = async (result) => {
    if (!result.destination) {
      return;
    }

    const items = this.reorder(
      this.state.listClassroomRelates,
      result.source.index,
      result.destination.index
    );


    await this.setState({
      listClassroomRelates: items,
    });
  }

  handleRemoveClassroomRelate = async (item) => {
    let dataRemove = [];
    let { listClassroomRelates } = this.state;



    if (listClassroomRelates) {
      dataRemove = listClassroomRelates.filter(value => value.id !== item.id);
    }

    this.setState({
      listClassroomRelates: dataRemove
    })
  }

  handleSearchChapterByValue = async (e) => {
    e.preventDefault();

    let { search_chapter } = this.state;


    let params = {
      // subject_id: this.props.classroom.subject.id,
      keyword: search_chapter,
      limit: 100,
    };
    await this.props.listChapter(params);
  }



  render() {
    // var {
    //   code,
    //   name,
    //   room,
    //   extra_number_student,
    //   promotion
    // } = this.state;

    const { promotion, origin_price, price } = this.state;


    let discountPercent = ((price - origin_price) / origin_price * 100).toFixed(0);

    return (
      <div>
        <div className="page-content page-container page-create-book page-classroom-create" id="page-content">
          <div className="padding">
            <h2 className='text-md text-highlight sss-page-title'>
              Chỉnh sửa lớp học
            </h2>
            <div className="general-info">
              <div>
                <h3 className="title-block">Thông tin chung</h3>
                <div className="classroom-actions">
                  <Link
								className='mr-14'
								style={{ cursor: "pointer" }}
								data-toggle='tooltip'
								title='Mã truy cập'
								to={
									"/classroom/" +
									this.props.match.params.id +
									"/code"
								}
							>Mã truy cập</Link>
              <Link
								className='mr-14'
								style={{ cursor: "pointer" }}
								data-toggle='tooltip'
								title='Báo cáo điểm'
								to={
									"/classroom/" +
									this.props.match.params.id +
									"/report"
								}
							>Điểm học tập</Link>
              <Link
								className='mr-14'
								style={{ cursor: "pointer" }}
								data-toggle='tooltip'
								title='Thành viên'
								to={
									"/classroom/" +
									this.props.match.params.id +
									"/member"
								}
							>Học sinh</Link>
                </div>
              </div>
              <div className="content">
                <input
                  onChange={this._onChange}
                  type="file"
                  className="form-control-file d-none"
                  name="files"
                  id="input-upload-image"
                />
                <div className="block-image">
                  {
                    !this.state.avtPreview
                      ?
                      <button type="button" onClick={this.handleUploadImage}>
                        <img src="/assets/img/icon-upload-file.svg" className="mr-10" alt="" />
                        <span>THÊM HÌNH</span>
                      </button>
                      :
                      <div className="block-image-overlay">
                        <img
                          id="output"
                          src={this.state.avtPreview}
                          alt="your image"
                          className="image"
                        />
                        <div className="middle">
                          <div className="text" onClick={this.remoAvatar}>Hủy chọn</div>
                        </div>
                      </div>
                  }
                </div>
                <div className="block-content">
                  <div className="item-input-text">
                    <div className="form-group mr-16" style={{ width: "144px" }}>
                      <label className="text-form-label">Mã khóa học</label>
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          name="code"
                          onChange={this._onChange}
                          style={{ background: "#ededed" }}
                          value={this.state.code}
                        />
                      </div>
                    </div>

                    <div className="form-group mr-16" style={{ width: "100%" }}>
                      <label className="text-form-label">Tên khóa học</label>
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          onChange={this._onChange}
                          value={this.state.name}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="item-input-text">
                    <div className="form-group mb-0 mr-32">
                      <label className="text-form-label">Phân loại</label>
                      <div>
                        <select
                          className="custom-select"
                          value={this.state.level}
                          name="level"
                          onChange={this._onChange}
                        >
                          <option value="">Cấp học</option>
                          <option value="1">Lớp 1</option>
                          <option value="2">Lớp 2</option>
                          <option value="3">Lớp 3</option>
                          <option value="4">Lớp 4</option>
                          <option value="5">Lớp 5</option>
                          <option value="6">Lớp 6</option>
                          <option value="7">Lớp 7</option>
                          <option value="8">Lớp 8</option>
                          <option value="9">Lớp 9</option>
                          <option value="10">Lớp 10</option>
                          <option value="11">Lớp 11</option>
                          <option value="12">Lớp 12</option>
                        </select>
                      </div>
                    </div>

                    <div className="form-group mb-0 mr-32">
                      <label className="text-form-label">
                        Phòng học
                      </label>
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          name="room"
                          onChange={this._onChange}
                          value={this.state.room}
                        />
                      </div>
                    </div>

                    <div className="form-group mr-32 mb-0">
                      <label className="text-form-label">Môn học</label>
                      <div>
                        <select
                          className="custom-select"
                          value={this.state.subject_id}
                          name="subject_id"
                          onChange={this._onChange}
                        >
                          <option value="">-- Chọn môn học --</option>
                          {this.fetchSubjectRows()}
                        </select>
                      </div>
                    </div>
                    <div className="form-group mb-0 mr-32">
                      <label className="text-form-label">Danh mục</label>
                      <div>
                        <div className="">
                          <select
                            className="custom-select"
                            value={this.state.group_id}
                            name="group_id"
                            onChange={this._onChange}
                          >
                            <option value="">-- Chọn danh mục --</option>
                            {this.fetchGroupRows()}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="form-group mb-0 mr-32">
                      <label className="text-form-label">Giáo viên</label>
                      <div>
                        <select
                          className="custom-select"
                          value={this.state.teacher_id}
                          name="teacher_id"
                          onChange={this._onChange}
                        >
                          <option value="">-- Chọn giáo viên --</option>
                          {this.fetchTeacherRows()}
                        </select>
                      </div>
                    </div>

                    <div className="form-group mb-0 mr-32">
                      <label className="text-form-label">Nổi bật</label>
                      <div className="mt-16">
                        <div className="float-left">
                          <label className="ui-switch ui-switch-md info m-t-xs">
                            <input
                              type="checkbox"
                              name="is_featured"
                              value={this.state.is_featured}
                              checked={this.state.is_featured === true ? 'checked' : ''}
                              onChange={this._onChange}
                            />{' '}
                            <i />
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="form-group mb-0 mr-32">
                      <label className="text-form-label">Hiển thị</label>
                      <div className="mt-16">
                        <div className="float-left">
                          <label className="ui-switch ui-switch-md info m-t-xs">
                            <input
                              type="checkbox"
                              name="status"
                              value={this.state.status}
                              checked={this.state.status === true ? 'checked' : ''}
                              onChange={this._onChange}
                            />{' '}
                            <i />
                          </label>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div className="block-price-discount">
              <h3 className="title-block">Giá và khuyến mãi</h3>
              <div className="content input-group">
                <div className="form-group mb-0 mr-32" style={{ width: "180px" }}>
                  <label className="text-form-label">Giá khóa học</label>
                  <div>
                    <input
                      type="number"
                      className="form-control"
                      name="origin_price"
                      onChange={this._onChange}
                      value={this.state.origin_price}
                    />
                  </div>
                </div>
                <div className="form-group mb-0 mr-32" style={{ width: "180px" }}>
                  <label className="text-form-label">Giá khuyến mãi</label>
                  <div>
                    <input
                      type="number"
                      className="form-control"
                      name="price"
                      onChange={this._onChange}
                      value={this.state.price}
                    />
                  </div>
                </div>
                <div className="form-group mb-0 mr-32" style={{ width: "160px" }}>
                  <label className="text-form-label">Chênh lệch</label>
                  <div className="percent-difference">
                    <span> {discountPercent && !isNaN(discountPercent) ? discountPercent : 0}%</span>
                  </div>
                </div>

                <div className="form-group mb-0 mr-32">
                  <label className="text-form-label">Chọn thời gian khuyến mãi</label>
                  <div>
                    <select
                      className="custom-select"
                      value={promotion.type}
                      name="type"
                      onChange={this._onChangeTypePromotion}
                    >
                      {/* <option value="">Chọn thời gian</option> */}
                      <option value="BY_DATE_RANGE">Khoảng thời gian cụ thể</option>
                      <option value="BY_HOUR">Thời gian kết thúc</option>
                    </select>
                  </div>
                </div>

                {
                  promotion.type == "BY_DATE_RANGE"
                  &&
                  <div className="form-group mb-0 mr-32">
                    <label className="text-form-label">Nhập thời gian</label>
                    <div className="group-date" style={{ display: "flex" }}>
                      <DatePicker
                        format={
                          "YYYY/MM/DD HH:mm"
                        }
                        value={promotion && promotion.from_date
                          ? moment(promotion.from_date)
                          : null}
                        showTime={{ format: 'HH:mm' }}
                        placeholder="Từ ngày"
                        onChange={this.changeDateStart}
                      />
                      <DatePicker
                        format={
                          "YYYY/MM/DD HH:mm"
                        }
                        value={promotion && promotion.to_date
                          ? moment(promotion.to_date)
                          : null}
                        showTime={{ format: 'HH:mm' }}
                        placeholder="Đến ngày"
                        onChange={this.changeDateEnd}
                        className="ml-2"
                      />
                    </div>
                  </div>
                }

                {
                  promotion.type == "BY_HOUR"
                  &&
                  <div className="form-group mb-0 mr-32" style={{ width: "180px" }}>
                    <label className="text-form-label">Nhập số giờ</label>
                    <div>
                      <input
                        type="number"
                        className="form-control"
                        name="hour"
                        onChange={this._onChangeTypePromotion}
                        value={promotion.hour}
                      />
                    </div>
                  </div>
                }

                <div className="form-group mb-0 mr-32" style={{ width: "180px" }}>
                  <label className="text-form-label">Số lượng khuyến mãi</label>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      name="quantity"
                      onChange={this._onChange}
                      value={this.state.quantity}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="block-price-discount">
              <h3 className="title-block">Học phí</h3>
              <div className="content input-group">
                <div className="form-group mb-0 mr-32" style={{ width: "180px" }}>
                  <label className="text-form-label">Theo ngày</label>
                  <div>
                    <input
                      type="number"
                      className="form-control"
                      name="hp_day"
                      onChange={this._onChange}
                      value={this.state.hp_day}
                    />
                  </div>
                </div>
                <div className="form-group mb-0 mr-32" style={{ width: "180px" }}>
                  <label className="text-form-label">1 ngày/1 tháng</label>
                  <div>
                    <input
                      type="number"
                      className="form-control"
                      name="hp_1month_day"
                      onChange={this._onChange}
                      value={this.state.hp_1month_day}
                    />
                  </div>
                </div>
                <div className="form-group mb-0 mr-32" style={{ width: "180px" }}>
                  <label className="text-form-label">1 ngày/3 tháng</label>
                  <div>
                    <input
                      type="number"
                      className="form-control"
                      name="hp_3month_day"
                      onChange={this._onChange}
                      value={this.state.hp_3month_day}
                    />
                  </div>
                </div>
                <div className="form-group mb-0 mr-32" style={{ width: "180px" }}>
                  <label className="text-form-label">1 ngày/6 tháng</label>
                  <div>
                    <input
                      type="number"
                      className="form-control"
                      name="hp_6month_day"
                      onChange={this._onChange}
                      value={this.state.hp_6month_day}
                    />
                  </div>
                </div>
                <div className="form-group mb-0 mr-32" style={{ width: "180px" }}>
                  <label className="text-form-label">1 ngày/12 tháng</label>
                  <div>
                    <input
                      type="number"
                      className="form-control"
                      name="hp_12month_day"
                      onChange={this._onChange}
                      value={this.state.hp_12month_day}
                    />
                  </div>
                </div>
                <div className="form-group mb-0">
                  <label className="text-form-label">
                    Số học sinh (Mở rộng)
                  </label>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      name="extra_number_student"
                      onChange={this._onChange}
                      value={this.state.extra_number_student}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="block-price-discount">
              <h3 className="title-block">Thông tin khác</h3>
              <div className="content input-group">
                <div className="form-group mb-0 mr-32" style={{ width: "180px" }}>
                  <label className="text-form-label">Hình thức học</label>
                  <div>
                    <Radio.Group
                      onChange={this._onChange}
                      name="is_online"
                      value={this.state.is_online}
                    >
                      <Radio value={false}>Offline</Radio>
                      <Radio value={true}>Online</Radio>
                    </Radio.Group>
                  </div>
                </div>
                <div className="form-group  mb-0 mr-32" style={{ width: "180px" }}>
                  <label className="text-form-label">Loại ca</label>
                  <div>
                    <Radio.Group
                      onChange={this._onChange}
                      name="is_cadup"
                      value={this.state.is_cadup}
                    >
                      <Radio value={false}>Ca đơn</Radio>
                      <Radio value={true}>Ca đúp</Radio>
                    </Radio.Group>
                  </div>
                </div>
                <div className="form-group  mb-0 mr-32" style={{ width: "180px" }}>
                  <label className="text-form-label">Tự động trừ buổi</label>
                  <div>
                    <Radio.Group
                      onChange={this._onChange}
                      name="is_auto_diff_day"
                      value={this.state.is_auto_diff_day}
                    >
                      <Radio value={true}>Tự động</Radio>
                      <Radio value={false}>Thủ công</Radio>
                    </Radio.Group>
                  </div>
                </div>
                <div className="form-group mb-0 mr-32" style={{ width: "250px" }}>
                  <label className="text-form-label">Link facebook page</label>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      name="link_fb_page"
                      onChange={this._onChange}
                      value={this.state.link_fb_page}
                    />
                  </div>
                </div>
                <div className="form-group mb-0 mr-32" style={{ width: "250px" }}>
                  <label className="text-form-label">Link facebook group</label>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      name="link_fb_group"
                      onChange={this._onChange}
                      value={this.state.link_fb_group}
                    />
                  </div>
                </div>
                <div className="form-group mb-0 mr-32" style={{ width: "250px" }}>
                  <label className="text-form-label">Video giới thiệu khóa học</label>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      name="video_intro"
                      onChange={this._onChange}
                      value={this.state.video_intro}
                    />
                  </div>
                </div>
                <div className="form-group mb-0 mr-32" style={{ width: "100px" }}>
                  <label className="text-form-label">Thứ tự</label>
                  <div>
                    <input
                      type="number"
                      className="form-control"
                      name="ordering"
                      onChange={this._onChange}
                      value={this.state.ordering}
                    />
                  </div>
                </div>
              </div>
              <div className="content input-group">
                <div className="form-group mb-0" style={{ width: "500px" }}>
                  <label className="text-form-label">
                    Ghi chú
                  </label>
                  <div>
                    <textarea
                      className="form-control"
                      placeholder="Nhập nội dung ghi chú"
                      name="note"
                      onChange={this._onChange}
                      value={this.state.note}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>

            <div className="block-attach-product">
              <div className="title-action">
                <h3 className="title-block mb-0 mr-18">Sách đề xuất</h3>
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#book-relate"
                  data-toggle-class="fade-down"
                  data-toggle-class-target=".animate"
                >
                  Thêm sách
                  <img src="/assets/img/icon-add.svg" alt="" className="ml-12" />
                </button>
              </div>

              <div className="block-list-product">
                <DragDropContext onDragEnd={this.onDragEndBookRelate}>
                  <Droppable droppableId="droppable" direction="horizontal">
                    {(provided, snapshot) => (
                      <ul
                        className="list-products ml-0 pl-0"
                        ref={provided.innerRef}
                        style={{
                          background: snapshot.isDragging ? "#e8f0fe" : "none",
                        }}
                      >
                        {
                          this.state.listBookRelates && this.state.listBookRelates.length > 0
                          &&
                          this.state.listBookRelates.map((item, index) => {
                            return (
                              <Draggable
                                key={index}
                                draggableId={"" + index}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <li
                                    className="list-item"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      ...provided.draggableProps.style,
                                      userSelect: "none",
                                      background: snapshot.isDragging
                                        ? "#e8f0fe"
                                        : "none",
                                      display: "table-row",
                                    }}
                                  >
                                    <div className="block-content">
                                      <div className="action-head">
                                        <a className="mr-14">
                                          <img src="/assets/img/icon-move.svg" alt="" />
                                        </a>
                                        <a onClick={() => this.handleRemoveBookRelate(item)}>
                                          <img src="/assets/img/icon-close.svg" alt="" />
                                        </a>
                                      </div>
                                      <div className="product-info">
                                        <div className="image">
                                          {
                                            item.avatar
                                              ?
                                              <img src={item.avatar} alt="" />
                                              :
                                              <img src="/assets/img/no-image.png" alt="" />
                                          }
                                        </div>
                                        <p className="name">
                                          {item.name ? item.name : ""}
                                        </p>
                                        <span className="price">{item.price ? baseHelpers.currencyFormat(item.price) : 0} đ</span>
                                      </div>
                                    </div>
                                  </li>
                                )}
                              </Draggable>
                            )
                          })
                        }
                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>

            <div className="block-attach-product">
              <div className="title-action">
                <h3 className="title-block mb-0 mr-18">Sách đi kèm</h3>
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#book-attached"
                  data-toggle-class="fade-down"
                  data-toggle-class-target=".animate"
                >
                  Thêm sách
                  <img src="/assets/img/icon-add.svg" alt="" className="ml-12" />
                </button>
              </div>

              <div className="block-list-product">
                <DragDropContext onDragEnd={this.onDragEndBookAttached}>
                  <Droppable droppableId="droppable" direction="horizontal">
                    {(provided, snapshot) => (
                      <ul
                        className="list-products ml-0 pl-0"
                        ref={provided.innerRef}
                        style={{
                          background: snapshot.isDragging ? "#e8f0fe" : "none",
                        }}
                      >
                        {
                          this.state.listBookAttacheds && this.state.listBookAttacheds.length > 0
                          &&
                          this.state.listBookAttacheds.map((item, index) => {
                            return (
                              <Draggable
                                key={index}
                                draggableId={"" + index}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <li
                                    className="list-item"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      ...provided.draggableProps.style,
                                      userSelect: "none",
                                      background: snapshot.isDragging
                                        ? "#e8f0fe"
                                        : "none",
                                      display: "table-row",
                                    }}
                                  >
                                    <div className="block-content">
                                      <div className="action-head">
                                        <a className="mr-14">
                                          <img src="/assets/img/icon-move.svg" alt="" />
                                        </a>
                                        <a onClick={() => this.handleRemoveBookAttached(item)}>
                                          <img src="/assets/img/icon-close.svg" alt="" />
                                        </a>
                                      </div>
                                      <div className="product-info">
                                        <div className="image">
                                          {
                                            item.avatar
                                              ?
                                              <img src={item.avatar} alt="" />
                                              :
                                              <img src="/assets/img/no-image.png" alt="" />
                                          }
                                        </div>
                                        <p className="name">
                                          {item.name ? item.name : ""}
                                        </p>
                                        <span className="price">{item.price ? baseHelpers.currencyFormat(item.price) : 0} đ</span>
                                      </div>
                                    </div>
                                  </li>
                                )}
                              </Draggable>
                            )
                          })
                        }
                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>

            <div className="block-attach-product">
              <div className="title-action">
                <h3 className="title-block mb-0 mr-18">Khóa học đề xuất</h3>
                <button
                  className="button"
                  data-toggle="modal"
                  data-target="#classroom-relate"
                  data-toggle-className="fade-down"
                  data-toggle-class-target=".animate"
                >
                  Thêm khóa Học
                  <img src="/assets/img/icon-add.svg" alt="" className="ml-12" />
                </button>
              </div>

              <div className="block-list-product">
                <DragDropContext onDragEnd={this.onDragEndClassroomRelate}>
                  <Droppable droppableId="droppable" direction="horizontal">
                    {(provided, snapshot) => (
                      <ul
                        className="list-products ml-0 pl-0"
                        ref={provided.innerRef}
                        style={{
                          background: snapshot.isDragging ? "#e8f0fe" : "none",
                        }}
                      >
                        {
                          this.state.listClassroomRelates && this.state.listClassroomRelates.length > 0
                          &&
                          this.state.listClassroomRelates.map((item, index) => {
                            return (
                              <Draggable
                                key={index}
                                draggableId={"" + index}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <li
                                    className="list-item"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      ...provided.draggableProps.style,
                                      userSelect: "none",
                                      background: snapshot.isDragging
                                        ? "#e8f0fe"
                                        : "none",
                                      display: "table-row",
                                    }}
                                  >
                                    <div className="block-content">
                                      <div className="action-head">
                                        <a className="mr-14">
                                          <img src="/assets/img/icon-move.svg" alt="" />
                                        </a>
                                        <a onClick={() => this.handleRemoveClassroomRelate(item)}>
                                          <img src="/assets/img/icon-close.svg" alt="" />
                                        </a>
                                      </div>
                                      <div className="product-info">
                                        <div className="image">
                                          {
                                            item.avatar
                                              ?
                                              <img src={item.avatar} alt="" />
                                              :
                                              <img src="/assets/img/no-image.png" alt="" />
                                          }
                                        </div>
                                        <p className="name">
                                          {item.name ? item.name : ""}
                                        </p>
                                        <span className="price">{item.price ? baseHelpers.currencyFormat(item.price) : 0} đ</span>
                                      </div>
                                    </div>
                                  </li>
                                )}
                              </Draggable>
                            )
                          })
                        }
                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>

            <div className="block-chapter">
              <div className="block-list-chapter block-chapter-selected">
                <h3 className="title-block">Danh sách chương của khóa học</h3>
                <DragDropContext onDragEnd={this.onDragEndChapterByClass}>
                  <Droppable droppableId="droppable" type="droppableItem">
                    {(provided, snapshot) => (
                      <div className="list" ref={provided.innerRef}>
                        {this.fetchListChaptersByClass()}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
              <div className="block-chapter-list">
                <div className="form-group" style={{ width: "100%" }}>
                  <h3 className="title-block">Tất cả chương</h3>
                  <div style={{ display: "flex" }}>
                    <input
                      type="text"
                      className="form-control mr-32"
                      name="search_chapter"
                      onChange={(e) => this.setState({ search_chapter: e.target.value })}
                      value={this.state.search_chapter}
                    />
                    <span className='input-group-append'>
                      <button
                        className='btn btn-white btn-sm'
                        type='button'
                        onClick={(e) => this.handleSearchChapterByValue(e)}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "#FF8345",
                          color: "#fff",
                          whiteSpace: "nowrap"
                        }}
                      >
                        <span>Tìm kiếm</span>
                        <span className='d-flex text-muted' style={{ marginLeft: "12px" }}>
                          <img src="/assets/img/icon-search.svg" alt="" />
                        </span>
                      </button>
                    </span>
                  </div>
                </div>
                <div className="block-list-chapter custom-classroom-create" >
                  <ul className="list">
                    {this.fetchListChapters()}
                  </ul>
                </div>
              </div>
            </div>

            <div className="block-description">
              <h3 className="title-block">Mô tả ngắn</h3>
              <div className="content">
                <Editor
                  apiKey={process.env.REACT_APP_API_KEY_TINY}
                  onInit={(evt, editor) => {
                    this._handleEditorDescriptionChange(
                      this.state.description,
                      editor
                    );
                  }}
                  value={this.state.description}
                  init={{
                    height: 500,
                    menubar: false,
                    images_file_types:
                      "jpeg,jpg,jpe,jfi,jif,jfif,png,gif,bmp,webp",
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount tiny_mce_wiris",
                    ],
                    external_plugins: {
                      tiny_mce_wiris:
                        "https://www.wiris.net/demo/plugins/tiny_mce/plugin.js",
                    },
                    toolbar:
                      "undo redo | formatselect | " +
                      "bold italic backcolor | image | alignleft aligncenter " +
                      "alignright alignjustify | bullist numlist outdent indent | fontselect |  fontsizeselect |" +
                      "searchreplace visualblocks code fullscreen | " +
                      "lists link advlist insertdatetime media | tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | table paste code removeformat | help",
                    fontsize_formats:
                      "8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt",
                    content_style:
                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    draggable_modal: true,
                    htmlAllowedTags: [".*"],
                    htmlAllowedAttrs: [".*"],
                    images_upload_handler: (
                      blobInfo,
                      success,
                      failure
                    ) =>
                      this._uploadImageCallBack(
                        blobInfo,
                        success,
                        failure
                      ),
                  }}
                  onEditorChange={
                    this._handleEditorDescriptionChange
                  }
                />
              </div>
            </div>
            <div className="block-editor-content">
              <h3 className="title-block">Nội dung</h3>
              <div className="content">
                <Editor
                  apiKey={process.env.REACT_APP_API_KEY_TINY}
                  onInit={(evt, editor) => {
                    this._handleEditorContentChange(
                      this.state.content,
                      editor
                    );
                  }}
                  value={this.state.content}
                  init={{
                    height: 500,
                    menubar: false,
                    images_file_types:
                      "jpeg,jpg,jpe,jfi,jif,jfif,png,gif,bmp,webp",
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount tiny_mce_wiris",
                    ],
                    external_plugins: {
                      tiny_mce_wiris:
                        "https://www.wiris.net/demo/plugins/tiny_mce/plugin.js",
                    },
                    toolbar:
                      "undo redo | formatselect | " +
                      "bold italic backcolor | image | alignleft aligncenter " +
                      "alignright alignjustify | bullist numlist outdent indent | fontselect |  fontsizeselect |" +
                      "searchreplace visualblocks code fullscreen | " +
                      "lists link advlist insertdatetime media | tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | table paste code removeformat | help",
                    fontsize_formats:
                      "8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt",
                    content_style:
                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    draggable_modal: true,
                    htmlAllowedTags: [".*"],
                    htmlAllowedAttrs: [".*"],
                    images_upload_handler: (
                      blobInfo,
                      success,
                      failure
                    ) =>
                      this._uploadImageCallBack(
                        blobInfo,
                        success,
                        failure
                      ),
                  }}
                  onEditorChange={this._handleEditorContentChange}
                />
              </div>
            </div>

            <div className="block-action-footer">
              <button type="button" className="btn-cancel">
                <img src="/assets/img/icon-arrow-left.svg" alt="" className="mr-14" />
                Hủy
              </button>
              <button type="button" className="btn-submit ml-16" onClick={(e) => this.handleSubmit(e)}>
                Cập nhật
                <img src="/assets/img/icon-arrow-right.svg" alt="" className="ml-14" />
              </button>
            </div>

          </div>
        </div>




        <ModalAttachedBook handleAddBook={this.handleAddBookAttached} handleAddSelectedBookAttched={this.handleAddSelectedBookAttched} selectedBookAttachedIDS={this.state.selectedBookAttachedIDS} selectedBooks={this.state.listBookAttacheds} />
        <ModalBookRelate
          handleAddBook={this.handleAddBookRelate}
          handleAddSelectedBookRelate={this.handleAddSelectedBookRelate}
          selectedBookRelateIDs={this.state.selectedBookRelateIDS}
          selectedBooks={this.state.listBookRelates}
        />
        <ModalClassroomRelate
          handleAddClassroom={this.handleAddClassroomRelate}
          selectedClassroom={this.state.listClassroomRelates}
          selectedClassroomRelateIDs={this.state.selectedClassroomRelateIDs}
          handleAddSelectedClassroomRelate={this.handleAddSelectedClassroomRelate}
        />

        <ModalEditLesson selectedCateId={this.state.selectedCateId} classroom_id={this.props.match.params.id} />
        {/* <div
          id="add-classroom"
          className="modal fade"
          data-backdrop="true"
          style={{
            display: "none",
            minWidth: "1000px",
          }}
          aria-hidden="true"
        >
          {this.state.subject_id && this.props.match.params.id ? (
            <Lesson
              subject_id={this.state.subject_id}
              classroom_id={this.props.match.params.id}
            />
          ) : (
            ""
          )}
        </div>
        <ModalAttachedBook classroom={this.props.classroom} bookAttached={this.props.bookAttached} />
        <ModalClassroomRelate classroom={this.props.classroom} classroomRelates={this.props.classroomRelates} />
        <ModalBookRelate classroom={this.props.classroom} bookRelates={this.props.bookRelates} /> */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    subjects: state.subject.subjects,
    bookAttached: state.classroom.bookAttached,
    classroom: state.classroom.classroom,
    cartCategories: state.classroom.cartCategories,
    redirect: state.classroom.redirect,
    students: state.student.students,
    classroomGroups: state.classroomGroup.classroomGroups,
    image: state.question.image,
    classroomRelates: state.classroom.classroomRelates,
    bookRelates: state.classroom.bookRelates,
    chapters: state.chapter.chapters,
    categories: state.category.categories,
    listSelectedchapters: state.chapter.listSelectedchapters
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showClassroom,
      updateClassroom,
      listSubject,
      listStudent,
      listClassroomGroup,
      uploadImage,
      listChapter,
      listCategory,
      updateMetaDataCategory,
      listChapterByClassRoom,
      updateMetaDataChapter,
      removeChapter,
      addChapter
    },
    dispatch
  );
}

let ClassEditContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ClassroomEdit)
);

export default ClassEditContainer;
