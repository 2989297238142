import React, { Component } from 'react';
import Moment from 'moment';
import { notification } from 'antd';
import Pagination from 'react-js-pagination';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import queryString from 'query-string';
import { listAdultEvaluation, checkInputItem, addDataRemoveAdultEval, onDeleteAdultEval } from './../../redux/adultEvaluation/action';
import { act } from 'react-dom/test-utils';
import { type } from 'jquery';

class Row extends Component {
	constructor(props) {
		super();
		this.state = {
			check: false,
			status: true,
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.props.check !== nextProps.check) {
			this.setState({
				check: nextProps.check,
			});
		}
	}

	handleCheckBox = e => {
		if (e.target.checked) {
			this.props.handleCheckedIds(this.props.obj._id, 'add');
			this.setState({
				check: e.target.checked
			});
		} else {
			this.props.handleCheckedIds(this.props.obj._id, 'remove');
			this.setState({
				check: e.target.checked
			});
		}
	};

	componentDidMount() {

	}

	handleChangeStatus = async e => {
		var name = e.target.name;
		var checked = e.target.checked;
		var value = e.target.value;

	};

	handleCheck = async (e) => {
		this.props.onDeleteOne(true);
		this.props.addDataRemoveAdultEval({
			ids: this.props.obj._id,
		});
	}

	render() {
		return (
			<tr className="v-middle" data-id={17}>
				<td>
					<label className="ui-check m-0">
						<input
							type="checkbox"
							className="checkInputItem"
							name="checkItem"
							value={this.props.obj._id}
							onChange={this.handleCheckBox}
						/>{' '}
						<i />
					</label>
				</td>
				<td className="flex">
					<Link
						className="item-author text-color"
						to={'/adult-evaluation/' + this.props.obj._id + '/edit'}>
						{this.props.obj.name}
					</Link>
				</td>
				<td className="text-left">
					<span className="item-amount d-none d-sm-block text-sm">
						{this.props.obj.description}
					</span>
				</td>
				<td className="text-left">
					<span className="item-amount d-none d-sm-block text-sm">
						{this.props.obj.content}
					</span>
				</td>
				<td className="text-left">
					<span className="item-amount d-none d-sm-block text-sm">
						{this.props.obj.type}
					</span>
				</td>
				<td className="text-left">
					<span className="item-amount d-none d-sm-block text-sm">
						<label className="ui-switch ui-switch-md info m-t-xs">
							<input
								type="checkbox"
								name="status"
								value={this.props.obj._id}
								checked={this.state.status === true ? 'checked' : ''}
								onChange={this.handleChangeStatus}
							/>{' '}
							<i />
						</label>
					</span>
				</td>
				<td className="text-center">
					<span className="item-amount d-none d-sm-block text-sm">
						{this.props.obj.updated_at &&
							Moment(this.props.obj.updated_at).format(
								'DD/MM/YYYY HH:mm:ss',
							)}
					</span>
				</td>
				<td>
					<div className="item-action dropdown">
						<a
							href="/"
							data-toggle="dropdown"
							className="text-muted">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width={16}
								height={16}
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								strokeWidth={2}
								strokeLinecap="round"
								strokeLinejoin="round"
								className="feather feather-more-vertical">
								<circle cx={12} cy={12} r={1} />
								<circle cx={12} cy={5} r={1} />
								<circle cx={12} cy={19} r={1} />
							</svg>
						</a>
						<div
							className="dropdown-menu dropdown-menu-right bg-white"
							role="menu">
							<Link
								className="dropdown-item"
								to={'/adult-evaluation/' + this.props.obj._id + '/edit'}>
								Sửa
							</Link>
							<div className="dropdown-divider" />
							<button
								onClick={this.handleCheck}
								className="dropdown-item trash"
								data-toggle="modal"
								data-target="#delete-video"
								data-toggle-class="fade-down"
								data-toggle-class-target=".animate">
								Xóa
							</button>
						</div>
					</div>
				</td>
			</tr>
		);
	}
}

class AdultEvaluation extends Component {
	constructor(props) {
		super();
		this.state = {
			keyword: "",
			data: [],
			limit: 20,
			page: 1,
			type: "",
			activePage: 1,
			ids: [],
			checkAll: false,
		};
	}

	fetchRows() {
		if (this.props.adultEvals instanceof Array) {
			return this.props.adultEvals.map((object, i) => {
				return (
					<Row
						obj={object}
						key={object._id}
						index={i}
						handleCheckedIds={this.handleCheckedIds}
						addDataRemoveAdultEval={this.props.addDataRemoveAdultEval}
						checkInputItem={this.props.checkInputItem}
						onDeleteOne={this.onDeleteOne}
						check={this.props.check}
					/>
				);
			});
		}
	}

	async componentDidMount() {
		const url = this.props.location.search;
		let params = queryString.parse(url);
		await this.setState({
			keyword: params.keyword ? params.keyword : "",
			type: params.type ? params.type : "",
			limit: params.limit ? params.limit : 20,
			page: params.page ? params.page : 1,
		})

		this.getData(this.state.activePage);
	}

	getData = async (pageNumber = 1) => {
		const params = {
			keyword: this.state.keyword,
			limit: this.state.limit,
			type: this.state.type,
		};

		params.page = pageNumber;

		await this.props.listAdultEvaluation(params);
	};


	// async componentDidMount() {
	// 	await this.props.listAdultEvaluation(this.getData());

	// 	if (this.props.limit) {
	// 		await this.setState({
	// 			limit: this.props.limit,
	// 			checkAll: false,
	// 			ids: this.props.ids
	// 		});
	// 	}
	// }

	onDeleteOne = async (onResetIds) => {
		if (onResetIds) {
			await this.setState({
				ids: []
			})
		}
	}

	handleCheckedIds = async (id, type = '') => {
		const _ids = this.state.ids;
		if (type === 'add') {
			if (_ids.indexOf(id) < 0) {
				_ids.push(id);
			}
		}
		if (type === 'remove') {
			let index = _ids.indexOf(id);
			if (index > -1) {
				_ids.splice(index, 1);
			}
		}

		await this.setState({
			ids: _ids
		})

	}

	onChange = e => {
		var name = e.target.name;
		var value = e.target.value;
		this.setState({
			[name]: value
		})
	};

	onSubmit = async (e) => {
		e.preventDefault();

		let { keyword, page, limit, type } = this.state;

		this.props.history.push(`/adult-evaluation?keyword=${keyword}&page=${page}&limit=${limit}&type=${type}`);

		await this.getData(1);
	};

	handleChangePage = async pageNumber => {
		window.scrollTo({ top: 0, behavior: "smooth" });
		await this.setState({
			page: pageNumber
		});

		let { keyword, page, limit, type } = this.state;

		this.props.history.push(`/adult-evaluation?keyword=${keyword}&page=${page}&limit=${limit}&type=${type}`);

		await this.getData(pageNumber);
	};

	handleDelete = async () => {
		let inputs = document.querySelectorAll('.checkInputItem');
		let data = this.props.dataRemoveAdultEval;

		if (this.state.ids && this.state.ids.length > 0) {
			data = {
				ids: this.state.ids
			};
		}

		await this.props.onDeleteAdultEval(data);
		this.getData(1);

		for (var i = 0; i < inputs.length; i++) {
			inputs[i].checked = false;
		}

		await this.setState({
			ids: []
		})
	};

	handleChange = async e => {
		var name = e.target.name;
		var value = e.target.value;
		await this.setState({
			[name]: value,
		});

		let { keyword, page, limit, type } = this.state;

		this.props.history.push(`/adult-evaluation?keyword=${keyword}&page=${page}&limit=${limit}&type=${type}`);

		await this.getData(1);
	};

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.props.checkAll !== nextProps.check) {
			this.setState({
				checkAll: nextProps.check,
			});
		}
	}

	handleCheckAll = async (e) => {
		var inputs = document.querySelectorAll('.checkInputItem');
		var flag = false;

		if (e.target.checked) {
			flag = true;
		}


		let _ids = [];
		for (let i = 0; i < inputs.length; i++) {
			inputs[i].checked = flag;
			if (flag) {
				_ids.push(inputs[i].value);
			} else {
				_ids = [];
			}
		}

		await this.setState({
			ids: _ids
		});


	};

	handleDeleteAll = async (e) => {
		var data = this.state.data;
		if (data.length === 0) {
			notification.warning({
				message: 'Vui lòng chọn mục để xóa !',
				placement: 'topRight',
				top: 50,
				duration: 3,
			});
		}
	}

	render() {
		let displayFrom =
			this.props.page === 1
				? 1
				: (parseInt(this.props.page) - 1) * this.props.limit;
		let displayTo =
			this.props.page === 1
				? this.props.limit
				: displayFrom + this.props.limit;
		displayTo = displayTo > this.props.total ? this.props.total : displayTo;
		return (
			<div>
				<div className="page-content page-container" id="page-content">
					<div className="padding">
						<h2 className='text-md text-highlight sss-page-title'>
							Đánh giá
						</h2>
						<div className="flex" />
						<div className="block-table-adult">
							<div className="toolbar">
								{/* <div className="btn-group">
									{this.props.ids.length !== 0 ? (
										<button
											className="btn btn-icon"
											data-toggle="modal"
											data-target="#delete-video"
											data-toggle-class="fade-down"
											data-toggle-class-target=".animate"
											title="Trash"
											id="btn-trash">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width={16}
												height={16}
												viewBox="0 0 24 24"
												fill="none"
												stroke="currentColor"
												strokeWidth={2}
												strokeLinecap="round"
												strokeLinejoin="round"
												className="feather feather-trash text-muted">
												<polyline points="3 6 5 6 21 6" />
												<path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
											</svg>
										</button>
									) : (
										<button
											className="btn btn-icon"
											onClick={this.handleDeleteAll}
											title="Trash"
											id="btn-trash">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width={16}
												height={16}
												viewBox="0 0 24 24"
												fill="none"
												stroke="currentColor"
												strokeWidth={2}
												strokeLinecap="round"
												strokeLinejoin="round"
												className="feather feather-trash text-muted">
												<polyline points="3 6 5 6 21 6" />
												<path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
											</svg>
										</button>
									)}
								</div> */}
								<form className="flex" onSubmit={this.onSubmit}>
									<div className="input-group">
										<input
											type="text"
											className="form-control form-control-theme keyword-custom"
											placeholder="Nhập từ khoá tìm kiếm..."
											onChange={this.onChange}
											name="keyword"
										/>{' '}
										<div className='ml-16'>
											<select
												className="custom-select"
												value={this.state.type}
												name="type"
												onChange={this.onChange}
											>
												<option value="">Nhóm</option>
												<option value="TOP_RANKS">TOP_RANKS</option>
												<option value="DANHGIA_PHUHUYNH">DANHGIA_PHUHUYNH</option>
											</select>
										</div>
										<span className="input-group-append">
											<button
												className="btn btn-white btn-sm"
												type="submit">
												<span className="d-flex text-muted">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width={16}
														height={16}
														viewBox="0 0 24 24"
														fill="none"
														stroke="currentColor"
														strokeWidth={2}
														strokeLinecap="round"
														strokeLinejoin="round"
														className="feather feather-search">
														<circle
															cx={11}
															cy={11}
															r={8}
														/>
														<line
															x1={21}
															y1={21}
															x2="16.65"
															y2="16.65"
														/>
													</svg>
												</span>
											</button>
										</span>
									</div>
								</form>
							</div>

							<div className="row">
								<div className="col-sm-12">
									<table className="table table-theme table-row v-middle">
										<thead className="text-muted">
											<tr>
												<th width="10px">
													<label className="ui-check m-0">
														<input
															type="checkbox"
															name="checkAll"
															id="checkAll"
															onChange={
																this.handleCheckAll
															}
														/>{' '}
														<i />
													</label>
													{
														this.state.ids.length !== 0
														&&
														<button
															className="btn btn-icon ml-16"
															data-toggle="modal"
															data-target="#delete-video"
															data-toggle-class="fade-down"
															data-toggle-class-target=".animate"
															title="Trash"
															id="btn-trash">
															<svg
																xmlns="http://www.w3.org/2000/svg"
																width={16}
																height={16}
																viewBox="0 0 24 24"
																fill="none"
																stroke="currentColor"
																strokeWidth={2}
																strokeLinecap="round"
																strokeLinejoin="round"
																className="feather feather-trash text-muted">
																<polyline points="3 6 5 6 21 6" />
																<path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
															</svg>
														</button>
													}
												</th>
												<th>Tên</th>
												<th className="text-left">
													Mô tả
												</th>
												<th width="350px" className="text-left">
													Nội dung
												</th>
												<th width="350px" className="text-left">
													Nhóm
												</th>
												<th className="text-left">
													Kích hoạt
												</th>
												<th className="text-center">
													Thời gian cập nhật
												</th>
												<th width="50px" />
											</tr>
										</thead>
										<tbody>{this.fetchRows()}</tbody>
									</table>
								</div>
							</div>

							<div className="row listing-footer">
								<div className="col-sm-1">
									<select
										className="custom-select w-70"
										name="limit"
										value={this.state.limit}
										onChange={this.handleChange}>
										<option value="20">20</option>
										<option value="50">50</option>
										<option value="100">100</option>
										<option value="-1">ALL</option>
									</select>
								</div>
								<div className="col-sm-6 showing-text">
									{' '}
									Hiển thị từ <b>{displayFrom}</b> đến{' '}
									<b>{displayTo}</b> trong tổng số{' '}
									<b>{this.props.total}</b>
								</div>
								{this.props.total !== 0 ? (
									<div className="col-sm-5 text-right">
										<Pagination
											activePage={this.props.page}
											itemsCountPerPage={this.props.limit}
											totalItemsCount={this.props.total}
											pageRangeDisplayed={10}
											onChange={this.handleChangePage}
										/>
									</div>
								) : (
									<div className="">Không có bản ghi nào</div>
								)}
							</div>

							<div
								id="delete-video"
								className="modal fade"
								data-backdrop="true"
								style={{ display: 'none' }}
								aria-hidden="true">
								<div
									className="modal-dialog animate fade-down"
									data-class="fade-down">
									<div className="modal-content">
										<div className="modal-header">
											<div className="modal-title text-md">
												Thông báo
											</div>
											<button
												className="close"
												data-dismiss="modal">
												×
											</button>
										</div>
										<div className="modal-body">
											<div className="p-4 text-center">
												<p>
													Bạn chắc chắn muốn xóa bản
													ghi này chứ?
												</p>
											</div>
										</div>
										<div className="modal-footer">
											<button
												type="button"
												className="btn btn-light"
												data-dismiss="modal">
												Đóng
											</button>
											<button
												type="button"
												onClick={this.handleDelete}
												className="btn btn-danger"
												data-dismiss="modal">
												Xoá
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		adultEvals: state.adultEvals.adultEvals ? state.adultEvals.adultEvals : [],
		limit: state.adultEvals.limit,
		total: state.adultEvals.total,
		page: state.adultEvals.page,
		ids: state.adultEvals.ids,
		check: state.adultEvals.checkAll,
		dataRemoveAdultEval: state.adultEvals.dataRemoveAdultEval
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			listAdultEvaluation,
			checkInputItem,
			addDataRemoveAdultEval,
			onDeleteAdultEval,
		},
		dispatch,
	);
}

let Container = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(AdultEvaluation),
);
export default Container;
