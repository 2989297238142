import React, { Component } from "react";
import Moment from "moment";
import { notification } from "antd";
import Pagination from "react-js-pagination";
import {
	listDocument,
	deleteDocument,
	addDelete,
	checkAll,
	addDataRemoveDocument
} from "../../redux/document/action";
import { listSubject } from "../../redux/subject/action";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import queryString from 'query-string';


class Row extends Component {
	constructor(props) {
		super();
		this.state = {
			check: false,
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.props.check !== nextProps.check) {
			this.setState({
				check: nextProps.check,
			});
		}
	}

	handleCheckBox = (e) => {
		if (e.target.checked) {
			this.props.handleCheckIds(this.props.obj._id, 'add');
			this.setState({
				check: e.target.checked
			})
		} else {
			this.props.handleCheckIds(this.props.obj._id, 'remove');
			this.setState({
				check: e.target.checked
			})
		}
	};

	handleCheck = (e) => {
		this.props.onDeleteOne(true);
		this.props.addDataRemoveDocument({
			ids: this.props.obj._id
		})
	};

	render() {
		const { subject } = this.props.obj;
		return (
			<tr className='v-middle table-row-item' data-id={17}>
				<td>
					<label className='ui-check m-0'>
						<input
							type='checkbox'
							name='id'
							className="checkInputItem"
							onChange={this.handleCheckBox}
						/>{" "}
						<i />
					</label>
				</td>
				<td className='flex'>
					<Link
						className='item-author text-color text-overflow-ellipsis'
						to={"/document/" + this.props.obj._id + "/edit"}
					>
						{this.props.obj.name}
					</Link>
				</td>
				<td className='text-left'>
					<span className='item-amount d-none d-sm-block text-sm'>
						{this.props.obj.teacher}
					</span>
				</td>
				<td className='text-left'>
					<span className='item-amount d-none d-sm-block text-sm'>
						{subject.name}
					</span>
				</td>
				<td className='text-center'>
					<span className='item-amount d-none d-sm-block text-sm'>
						{this.props.obj.classroom ? this.props.obj.classroom.name : ''}
					</span>
				</td>
				<td className='text-center'>
					<span className='item-amount d-none d-sm-block text-sm'>
						{this.props.obj.updated_at &&
							Moment(this.props.obj.updated_at).format(
								"DD/MM/YYYY HH:mm:ss"
							)}
					</span>
				</td>
				<td className="text-right">
					<div className='item-action'>
						<Link
							className='mr-14'
							data-toggle='tooltip'
							title='Chỉnh sửa'
							to={"/document/" + this.props.obj._id + "/edit"}
						>
							<img src="/assets/img/icon-edit.svg" alt="" />
						</Link>
						<div
							data-toggle='tooltip'
							title='Xóa'
						>
							<a
								onClick={this.handleCheck}
								className='trash'
								data-toggle='modal'
								data-target='#delete-video'
								data-toggle-class='fade-down'
								data-toggle-class-target='.animate'
							>
								<img src="/assets/img/icon-delete.svg" alt="" />
							</a>
						</div>
					</div>
				</td>
			</tr>
		);
	}
}

class Document extends Component {
	constructor(props) {
		super();
		this.state = {
			data: [],
			keyword: "",
			limit: 20,
			page: 1,
			subject_id: "",
			activePage: 1,
			checkAll: false,
			ids: []
		};
	}

	fetchRows() {
		if (this.props.documents instanceof Array) {
			return this.props.documents.map((object, i) => {
				return (
					<Row
						obj={object}
						key={object._id}
						index={i}
						addDelete={this.props.addDelete}
						listDocument={this.props.listDocument}
						getData={this.getData}
						check={this.props.check}
						handleCheckIds={this.handleCheckIds}
						onDeleteOne={this.onDeleteOne}
						addDataRemoveDocument={this.props.addDataRemoveDocument}
					/>
				);
			});
		}
	}

	async componentDidMount() {
		const url = this.props.location.search;
		let params = queryString.parse(url);

		await this.setState({
			keyword: params.keyword ? params.keyword : "",
			subject_id: params.subject_id ? params.subject_id : "",
			limit: params.limit ? params.limit : 20,
			page: params.page ? params.page : 1,
		})

		const data = {
			limit: 999,
			is_delete: false,
		};

		await this.props.listSubject(data);

		this.getData(this.state.activePage);
	}


	onChange = async (e) => {
		e.preventDefault();

		var name = e.target.name;
		var value = e.target.value;
		await this.setState({
			[name]: value,
		});
		let { keyword, subject_id, page, limit } = this.state;

		this.props.history.push(`/document?keyword=${keyword}&limit=${limit}&page=${page}&subject_id=${subject_id}`);

		await this.getData(1);
	};

	onDeleteOne = (onResetIds) => {
		if (onResetIds) {
			this.setState({
				ids: []
			})
		}
	}


	handleCheckIds = async (id, type = '') => {
		var _ids = this.state.ids;
		if (type === 'add') {
			if (_ids.indexOf(id) < 0) {
				_ids.push(id);
			}
		}
		if (type === 'remove') {
			const index = _ids.indexOf(id);
			if (index > -1) {
				_ids.splice(index, 1);
			}
		}

		await this.setState({
			ids: _ids
		});
	}

	getData = (pageNumber = 1) => {
		const params = {
			keyword: this.state.keyword,
			subject_id: this.state.subject_id,
			limit: this.state.limit,
		};

		params.page = pageNumber;

		this.props.listDocument(params);
	};

	onSubmit = async (e) => {

		e.preventDefault();
		let { keyword, subject_id, page, limit } = this.state;

		this.props.history.push(`/document?keyword=${keyword}&limit=${limit}&page=${page}&subject_id=${subject_id}`);

		await this.getData(1);
	};

	handleChangePage = async (pageNumber) => {
		window.scrollTo({ top: 0, behavior: "smooth" });
		await this.setState({
			page: pageNumber
		})

		let { keyword, subject_id, page, limit } = this.state;

		this.props.history.push(`/document?keyword=${keyword}&limit=${limit}&page=${page}&subject_id=${subject_id}`);
		await this.getData(pageNumber);
	};


	handleDelete = async () => {
		let inputs = document.querySelectorAll('.checkInputItem');
		let data = this.props.dataRemoveDocument;

		if (this.state.ids && this.state.ids.length > 0) {
			data = {
				ids: this.state.ids
			};
		}

		await this.props.deleteDocument(data);
		this.getData();

		for (let i = 0; i < inputs.length; i++) {
			inputs[i].checked = false;
		}
	};

	handleChange = async (e) => {
		var name = e.target.name;
		var value = e.target.value;
		await this.setState({
			[name]: value,
		});
		let { keyword, subject_id, page, limit } = this.state;

		this.props.history.push(`/document?keyword=${keyword}&limit=${limit}&page=${page}&subject_id=${subject_id}`);


		await this.getData(1);
	};

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.props.checkAll !== nextProps.check) {
			this.setState({
				checkAll: nextProps.check,
			});
		}
	}

	handleCheckAll = (e) => {
		var inputs = document.querySelectorAll('.checkInputItem');
		var flag = false;

		if (e.target.checked) {
			flag = true;
		}

		var _ids = [];
		for (var i = 0; i < inputs.length; i++) {
			inputs[i].checked = flag;
			if (flag) {
				_ids.push(inputs[i].value);
			} else {
				_ids = [];
			}
		}

		this.setState({
			ids: _ids
		});
	};

	fetchRowsSubject() {
		if (this.props.subjects instanceof Array) {
			return this.props.subjects.map((obj, i) => {
				return (
					<option value={obj._id} key={obj._id.toString()}>
						{obj.name}
					</option>
				);
			});
		}
	}

	render() {
		let displayFrom =
			this.props.page === 1
				? 1
				: (parseInt(this.props.page) - 1) * this.props.limit;
		let displayTo =
			this.props.page === 1
				? this.props.limit
				: displayFrom + this.props.limit;
		displayTo = displayTo > this.props.total ? this.props.total : displayTo;
		return (
			<div>
				<div className='page-content page-container' id='page-content'>
					<div className='padding'>
						<h2 className="text-md text-highlight sss-page-title">Quản lý tài liệu</h2>
						<div className='block-table-document'>
							<div className='toolbar'>
								<form className='flex' onSubmit={this.onSubmit}>
									<div className='input-group'>
										<input
											type='text'
											className='form-control form-control-theme keyword-custom'
											placeholder='Nhập từ khoá tìm kiếm...'
											onChange={this.onChange}
											name='keyword'
											value={this.state.keyword}
										/>{" "}
										<span className='input-group-append'>
											<button
												className='btn btn-white btn-sm'
												type='submit'
											>
												<span className='d-flex text-muted'>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														width={16}
														height={16}
														viewBox='0 0 24 24'
														fill='none'
														stroke='currentColor'
														strokeWidth={2}
														strokeLinecap='round'
														strokeLinejoin='round'
														className='feather feather-search'
													>
														<circle
															cx={11}
															cy={11}
															r={8}
														/>
														<line
															x1={21}
															y1={21}
															x2='16.65'
															y2='16.65'
														/>
													</svg>
												</span>
											</button>
										</span>
										<select
											style={{ maxWidth: 300 }}
											className='custom-select ml-16'
											value={this.state.subject_id}
											name='subject_id'
											onChange={this.onChange}
										>
											<option value=''>
												-- Chọn môn học --
											</option>
											{this.fetchRowsSubject()}
										</select>
									</div>
								</form>
							</div>

							<div className='row'>
								<div className='col-sm-12'>
									<table className='table table-theme table-row v-middle'>
										<thead className='text-muted'>
											<tr>
												<th width='10px'>
													<label className='ui-check m-0'>
														<input
															type='checkbox'
															name='id'
															onChange={this.handleCheckAll}
														/>{" "}
														<i />
													</label>
													{
														this.state.ids.length !== 0
														&&
														<button
															className='btn btn-icon'
															data-toggle='modal'
															data-target='#delete-video'
															data-toggle-class='fade-down'
															data-toggle-class-target='.animate'
															title='Trash'
															id='btn-trash'
														>
															<svg
																xmlns='http://www.w3.org/2000/svg'
																width={16}
																height={16}
																viewBox='0 0 24 24'
																fill='none'
																stroke='currentColor'
																strokeWidth={2}
																strokeLinecap='round'
																strokeLinejoin='round'
																className='feather feather-trash text-muted'
															>
																<polyline points='3 6 5 6 21 6' />
																<path d='M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2' />
															</svg>
														</button>
													}
												</th>
												<th>Tên tài liệu</th>
												<th className='text-left'>
													Giáo viên
												</th>
												<th className='text-left'>
													Môn học
												</th>
												<th className='text-center'>
													Lớp học
												</th>
												<th className='text-center'>
													Thời gian cập nhật
												</th>
												<th className="text-right">
													Thao tác
												</th>
											</tr>
										</thead>
										<tbody>{this.fetchRows()}</tbody>
									</table>
								</div>
							</div>

							<div className='row listing-footer'>
								<div className='col-sm-1'>
									<select
										className='custom-select w-70'
										name='limit'
										value={this.state.limit}
										onChange={this.handleChange}
									>
										<option value='20'>20</option>
										<option value='50'>50</option>
										<option value='100'>100</option>
										<option value='-1'>ALL</option>
									</select>
								</div>
								<div className='col-sm-6 showing-text'>
									{" "}
									Hiển thị từ <b>{displayFrom}</b> đến{" "}
									<b>{displayTo}</b> trong tổng số{" "}
									<b>{this.props.total}</b>
								</div>
								{this.props.total !== 0 ? (
									<div className='col-sm-5 text-right'>
										<Pagination
											activePage={this.props.page}
											itemsCountPerPage={this.props.limit}
											totalItemsCount={this.props.total}
											pageRangeDisplayed={10}
											onChange={this.handleChangePage}
										/>
									</div>
								) : (
									<div className=''>Không có bản ghi nào</div>
								)}
							</div>

							<div
								id='delete-video'
								className='modal fade'
								data-backdrop='true'
								style={{ display: "none" }}
								aria-hidden='true'
							>
								<div
									className='modal-dialog animate fade-down'
									data-class='fade-down'
								>
									<div className='modal-content'>
										<div className='modal-header'>
											<div className='modal-title text-md'>
												Thông báo
											</div>
											<button
												className='close'
												data-dismiss='modal'
											>
												×
											</button>
										</div>
										<div className='modal-body'>
											<div className='p-4 text-center'>
												<p>
													Bạn chắc chắn muốn xóa bản
													ghi này chứ?
												</p>
											</div>
										</div>
										<div className='modal-footer'>
											<button
												type='button'
												className='btn btn-light'
												data-dismiss='modal'
											>
												Đóng
											</button>
											<button
												type='button'
												onClick={this.handleDelete}
												className='btn btn-danger'
												data-dismiss='modal'
											>
												Xoá
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		documents: state.document.documents,
		limit: state.document.limit,
		page: state.document.page,
		total: state.document.total,
		ids: state.document.ids,
		check: state.document.checkAll,
		subjects: state.subject.subjects,
		dataRemoveDocument: state.document.dataRemoveDocument
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{ listDocument, deleteDocument, addDelete, checkAll, listSubject, addDataRemoveDocument },
		dispatch
	);
}

let RowContainer = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(Row)
);
let DocumentContainer = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(Document)
);
export default DocumentContainer;
