import moment from 'moment';
import Moment from "moment-timezone";

class BaseHelper {
    renderQuestionHTML(question) {
        let _content = '';
        try {
            const kind = question.kind;
            const content = question.content;
            if (kind === 'text/0')
                _content += `<span>${content}</span>`;

            if (kind === 'text/1')
                _content += `<b>${content}</b>`;

            if (kind === 'text/2')
                _content += `<em>${content}</em>`;

            if (kind === 'text/3')
                _content += `<b><em>${content}</em><b>`;

            if (kind === 'text/4')
                _content += `<u>${content}</u>`;

            if (kind === 'text/8')
                _content += `<span style="background:yellow">${content}</span>`;

            if (kind === 'text/16')
                _content += `<sub>${content}</sub>`;

            if (kind === 'text/32')
                _content += `<sup>${content}</sup>`;

            if (kind === 'text/64')
                _content += `<b>${content}</b>`;

            if (kind === 'br')
                _content += `<br/>`;

            if (kind === 'error/0')
                _content += `<span style="background:red">ERROR!: ${content}</span>`;

            if (kind === 'image/png') {
                const base64Image = `data:${kind};base64,${content}`;
                _content += `<img src=${base64Image} alt=""/>`;
            }
        } catch (err) {
            console.log(err);
        }

        return _content;
    }

    currencyFormat(value) {
        return value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
    formatDateToString(value) {
        return moment(value).format('DD/MM/YYYY HH:mm:ss')
    }

    getFromDate(date) {
        if (!this.getMoment().isMoment(date)) {
            return date;
        }

        const _date = date.clone();
        _date.set({
            hour: 0,
            minute: 0,
            second: 0,
            milisecond: 0,
        });

        return _date;
    }

    getToDate(date) {
        if (!this.getMoment().isMoment(date)) {
            return date;
        }

        const _date = date.clone();
        _date.set({
            hour: 23,
            minute: 59,
            second: 59,
            milisecond: 999,
        });

        return _date;
    }
    getMoment() {
        if (!this.moment) {
            this.moment = Moment;
        }

        return this.moment;
    }


    //upload file binary

    getFormDataUpload(files, folder) {
        const form = new FormData();
        for (let i = 0; i < files.length; i++) {
          const item = files[i];
        //   if (item.size / 1024 / 1024 > appConfig.IMAGE_SIZE_ALLOW) {
        //     return false;
        //   }
          form.append("files", item, item.name);
          form.append("folder", folder);
        }
        return form;
      }

}

export default new BaseHelper();
