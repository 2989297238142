import axios from 'axios';
import * as ActionTypes from './type';
import { initAPI, responseError, notify } from '../../config/api';

export function uploadBanner(data) {
	initAPI();
	return async dispatch => {
		await axios
			.post(`file/upload`, data)
			.then(res => {
				notify(res, false);
				if (res.data.code === 200) {
					const data = res.data.data;
					dispatch({
						type: ActionTypes.UPLOAD_BANNER,
						data,
					});
				}
			})
			.catch(async err => {
				responseError(err);
			});
	};
}

export function uploadImageOutstanding(data) {
	initAPI();
	return async dispatch => {
		await axios
			.post(`file/upload`, data)
			.then(res => {
				notify(res, false);
				if (res.data.code === 200) {
					const data = res.data.data;
					dispatch({
						type: ActionTypes.UPLOAD_IMAGE_OUTSTANSDING,
						data,
					});
				}
			})
			.catch(async err => {
				responseError(err);
			});
	};
}

export function uploadImageAudition(data) {
	initAPI();
	return async dispatch => {
		await axios
			.post(`file/upload`, data)
			.then(res => {
				notify(res, false);
				if (res.data.code === 200) {
					const data = res.data.data;
					dispatch({
						type: ActionTypes.UPLOAD_IMAGE_AUDITION,
						data,
					});
				}
			})
			.catch(async err => {
				responseError(err);
			});
	};
}

export function uploadImageSchedule(data) {
	initAPI();
	return async dispatch => {
		await axios
			.post(`file/upload`, data)
			.then(res => {
				notify(res, false);
				if (res.data.code === 200) {
					const data = res.data.data;
					dispatch({
						type: ActionTypes.UPLOAD_IMAGE_SCHEDULE,
						data,
					});
				}
			})
			.catch(async err => {
				responseError(err);
			});
	};
}
