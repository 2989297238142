export const LIST_QUESTION = 'LIST_QUESTION';
export const CREATE_QUESTION = 'CREATE_QUESTION';
export const SHOW_QUESTION = 'SHOW_QUESTION';
export const UPDATE_QUESTION = 'UPDATE_QUESTION';
export const DELETE_QUESTION = 'DELETE_QUESTION';
export const ADD_DELETE = 'ADD_DELETE';
export const CHECK_ALL = 'CHECK_ALL';
export const REMOVE_CLASSROOM = 'REMOVE_CLASSROOM';
export const ADD_CLASSROOM = 'ADD_CLASSROOM';
export const GET_LIST_CLASSROOM = 'GET_LIST_CLASSROOM';
export const DATA_REMOVE_QUESTION = 'DATA_REMOVE_QUESTION';