import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showPost, updatePost } from '../../redux/blog/action';
// import { listAdmin } from "../../redux/student/action";
import { listBlogCategory } from '../../redux/blogCategory/action';
import { Editor } from "@tinymce/tinymce-react";

class BlogEdit extends Component {
    constructor(props) {
        super();
        this.state = {
            name: '',
            description: '',
            files: [],
            external_link: '',
            is_featured: false,
            status: true,
            category_id: null,
            content: ""
        };
    }

    async componentDidMount() {
        await this.props.showPost(this.props.match.params.id);
        const params = {
			keyword: "",
			limit: 100,
			page: 1,
			is_delete: false
		};

		await this.props.listBlogCategory(params);

        if (this.props.post) {
            let { name, description, files, external_link, status, teacher_id, content, is_featured, category } = this.props.post;

            this.setState({
                name,
                description,
                files,
                external_link,
                status,
                content,
                is_featured,
                category_id: category ? category.id : null
            })
        }
    }

    _onChange = async e => {
        var name = e.target.name;
        let value = e.target.value;

        let checked = e.target.checked;
		if (name === "status" || name == "is_featured") {
			value = checked;
		}
        if (name === 'files') {
            value = await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(e.target.files[0]);
                reader.onload = () => {
                    resolve(reader.result);
                }
                reader.onerror = error => reject(error);
            });
            value = [value];
        }
        await this.setState({
            [name]: value,
        });
    };

    handleSubmit = e => {
        e.preventDefault();

        const data = {
            id: this.props.match.params.id,
            name: this.state.name,
            description: this.state.description,
            external_link: this.state.external_link,
            files: this.state.files,
            status: this.state.status,
            category_id: this.state.category_id,
            content: this.state.content,
            is_featured: this.state.is_featured
        };
        this.props.updatePost(data);
    };

    fetchTeacherRows() {
        if (this.props.categories instanceof Array) {
            return this.props.categories.map((obj, i) => {
                return (
                    <option value={obj._id} key={obj._id.toString()}>
                        {obj.name}
                    </option>
                );
            });
        }
    }

    fetchRows() {

    }

    _handleEditorDescriptionChange = (value, editor) => {
        this.setState({ description: value });
    };
    _handleEditorContentChange = (value, editor) => {
        this.setState({ content: value });
    };

    render() {
        return (
            <div>
                <div className="page-content page-container" id="page-content">
                    <div className="padding">
                        <div className="row">
                            <div className="col-md-10">
                                <div className="card">
                                    <div className="card-header">
                                        <strong>Cập nhật bài viết</strong>
                                    </div>
                                    <div className="card-body">
                                        <div className="form-group row">
                                            <label className="col-sm-4 col-form-label">
                                                Tiêu đề
                                            </label>
                                            <div className='col-sm-8'>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="name"
                                                    onChange={this._onChange}
                                                    value={this.state.name}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-4 col-form-label">Chọn danh mục</label>
                                            <div className='col-sm-8'>
                                                <select
                                                    className="custom-select"
                                                    value={this.state.category_id}
                                                    name="category_id"
                                                    onChange={this._onChange}
                                                >
                                                    <option value="">-- Chọn --</option>
                                                    {this.fetchTeacherRows()}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-4 col-form-label">
                                                Hình ảnh
                                            </label>
                                            <div className="col-sm-4">
                                                <input type="file" onChange={this._onChange} className="form-control-file" name="files" />
                                            </div>
                                            <div className="col-sm-4 d-flex">
                                                <img alt="" src={
                                                    this.props.post
                                                        ?
                                                        this.props.post.image
                                                        : ''
                                                }
                                                    style={{ width: '200px' }} />
                                            </div>
                                        </div>
                                        <div className="form-group row">

                                            <label className="col-sm-4 col-form-label">
                                                External_link
                                            </label>
                                            <div className="col-sm-8">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="external_link"
                                                    onChange={this._onChange}
                                                    value={this.state.external_link}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-4 col-form-label">
                                                Mô tả ngắn
                                            </label>
                                            <div className="col-sm-8">
                                                <Editor
                                                    apiKey={process.env.REACT_APP_API_KEY_TINY}
                                                    onInit={(evt, editor) => {
                                                        this._handleEditorDescriptionChange(
                                                            this.state.description,
                                                            editor
                                                        );
                                                    }}
                                                    value={this.state.description}
                                                    init={{
                                                        height: 400,
                                                        menubar: false,
                                                        images_file_types:
                                                            "jpeg,jpg,jpe,jfi,jif,jfif,png,gif,bmp,webp",
                                                        plugins: [
                                                            "advlist autolink lists link image charmap print preview anchor",
                                                            "searchreplace visualblocks code fullscreen",
                                                            "insertdatetime media table paste code help wordcount tiny_mce_wiris",
                                                        ],
                                                        external_plugins: {
                                                            tiny_mce_wiris:
                                                                "https://www.wiris.net/demo/plugins/tiny_mce/plugin.js",
                                                        },
                                                        toolbar:
                                                            "undo redo | formatselect | " +
                                                            "bold italic backcolor | image | alignleft aligncenter " +
                                                            "alignright alignjustify | bullist numlist outdent indent | fontselect |  fontsizeselect |" +
                                                            "searchreplace visualblocks code fullscreen | " +
                                                            "lists link advlist insertdatetime media | tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | table paste code removeformat | help",
                                                        fontsize_formats:
                                                            "8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt",
                                                        content_style:
                                                            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                                        draggable_modal: true,
                                                        htmlAllowedTags: [".*"],
                                                        htmlAllowedAttrs: [".*"],
                                                        images_upload_handler: (
                                                            blobInfo,
                                                            success,
                                                            failure
                                                        ) =>
                                                            this._uploadImageCallBack(
                                                                blobInfo,
                                                                success,
                                                                failure
                                                            ),
                                                    }}
                                                    onEditorChange={this._handleEditorDescriptionChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-4 col-form-label">
                                                Nội dung
                                            </label>
                                            <div className="col-sm-8">
                                                <Editor
                                                    apiKey={process.env.REACT_APP_API_KEY_TINY}
                                                    onInit={(evt, editor) => {
                                                        this._handleEditorContentChange(
                                                            this.state.content,
                                                            editor
                                                        );
                                                    }}
                                                    value={this.state.content}
                                                    init={{
                                                        height: 400,
                                                        menubar: false,
                                                        images_file_types:
                                                            "jpeg,jpg,jpe,jfi,jif,jfif,png,gif,bmp,webp",
                                                        plugins: [
                                                            "advlist autolink lists link image charmap print preview anchor",
                                                            "searchreplace visualblocks code fullscreen",
                                                            "insertdatetime media table paste code help wordcount tiny_mce_wiris",
                                                        ],
                                                        external_plugins: {
                                                            tiny_mce_wiris:
                                                                "https://www.wiris.net/demo/plugins/tiny_mce/plugin.js",
                                                        },
                                                        toolbar:
                                                            "undo redo | formatselect | " +
                                                            "bold italic backcolor | image | alignleft aligncenter " +
                                                            "alignright alignjustify | bullist numlist outdent indent | fontselect |  fontsizeselect |" +
                                                            "searchreplace visualblocks code fullscreen | " +
                                                            "lists link advlist insertdatetime media | tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | table paste code removeformat | help",
                                                        fontsize_formats:
                                                            "8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt",
                                                        content_style:
                                                            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                                        draggable_modal: true,
                                                        htmlAllowedTags: [".*"],
                                                        htmlAllowedAttrs: [".*"],
                                                        images_upload_handler: (
                                                            blobInfo,
                                                            success,
                                                            failure
                                                        ) =>
                                                            this._uploadImageCallBack(
                                                                blobInfo,
                                                                success,
                                                                failure
                                                            ),
                                                    }}
                                                    onEditorChange={this._handleEditorContentChange}
                                                />
                                            </div>
                                        </div>
                                        {/* <div className="form-group row">
                                            <div className="col-sm-12">
                                                <label className=" col-form-label">
                                                    Trạng thái
                                                </label>
                                                <div>
                                                    <div className="form-check float-left">
                                                        <input checked={this.state.status === true || this.state.status === "true"} className="form-check-input"
                                                            type="radio" name="status" value="true" id="gridRadios1"
                                                            onChange={this._onChange} defaultValue="option1" />
                                                        <label className="form-check-label" htmlFor="gridRadios1">
                                                            Hiển thị
                                                        </label>
                                                    </div>
                                                    <div className="form-check float-left ml-4">
                                                        <input checked={this.state.status === false || this.state.status === "false"}
                                                            className="form-check-input" type="radio" name="status" value="false" id="gridRadios2" onChange={this._onChange} defaultValue="option2" />
                                                        <label className="form-check-label" htmlFor="gridRadios2">
                                                            Ẩn
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}

                                        <div className="form-group row">
                                            <label className="text-form-label col-sm-4">Hiển thị</label>
                                            <div className="col-sm-8">
                                                <div className="float-left">
                                                    <label className="ui-switch ui-switch-md info m-t-xs">
                                                        <input
                                                            type="checkbox"
                                                            name="status"
                                                            value={this.state.status}
                                                            checked={this.state.status === true ? 'checked' : ''}
                                                            onChange={this._onChange}
                                                        />{' '}
                                                        <i />
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="text-form-label col-sm-4">Nổi bật</label>
                                            <div className="col-sm-8">
                                                <div className="float-left">
                                                    <label className="ui-switch ui-switch-md info m-t-xs">
                                                        <input
                                                            type="checkbox"
                                                            name="is_featured"
                                                            value={this.state.is_featured}
                                                            checked={this.state.is_featured === true ? 'checked' : ''}
                                                            onChange={this._onChange}
                                                        />{' '}
                                                        <i />
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <div className="col-sm-12 text-right">
                                                <button
                                                    className="btn btn-primary mt-2"
                                                    onClick={this.handleSubmit}>
                                                    Cập nhật
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

function mapStateToProps(state) {
    return {
        post: state.blog.post,
        redirect: true,
        categories: state.blogCategory.blogCategories,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        showPost,
        updatePost,
        listBlogCategory
    }, dispatch);
}

let ContainerEdit = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(BlogEdit),
);

export default ContainerEdit;
