import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createSubject } from "../../redux/subject/action";
import { listAdmin } from "../../redux/student/action";
import { Radio } from "antd";

class SubjectCreate extends Component {
	constructor(props) {
		super();
		this.state = {
			name: "",
			code: "",
			is_online: false,
			support_fb_link: "",
			teacher_id: "",
			supporter_id: "",
			ordering: 0,
			status: false
		};
	}

	getData = () => {
		const data = {
			limit: 50
		};

		return data;
	};

	async componentDidMount() {
		await this.props.listAdmin(this.getData());
	}

	fetchRows(group = null) {
		if (this.props.students instanceof Array) {
			return this.props.students.map((obj, i) => {
				if (group && obj.user_group === group) {
					return (
						<option value={obj._id} key={obj._id.toString()}>
							{obj.fullname}
						</option>
					);
				}
			});
		}
	}

	_onChange = (e) => {
		var name = e.target.name;
		var value = e.target.value;
		this.setState({
			[name]: value,
		});
	};

	handleSubmit = async (e) => {
		e.preventDefault();
		await this.props.createSubject({
			name: this.state.name,
			code: this.state.code,
			is_online: this.state.is_online,
			support_fb_link: this.state.support_fb_link,
			teacher_id: this.state.teacher_id,
			supporter_id: this.state.supporter_id,
			ordering: this.state.ordering,
			status: this.state.status
		});
		if (this.props.redirect === true) {
			await this.props.history.push("/subject");
		}
	};

	handleSave = async (e) => {
		e.preventDefault();
		const data = {
			name: this.state.name,
			code: this.state.code,
			is_online: this.state.is_online,
			support_fb_link: this.state.support_fb_link,
			teacher_id: this.state.teacher_id,
			supporter_id: this.state.supporter_id,
			ordering: this.state.ordering,
			status: this.state.status
		};
		await this.props.createSubject(data);
		if (this.props.redirect === true) {
			await this.setState({
				name: "",
				code: "",
				is_online: false,
				support_fb_link: "",
				teacher_id: "",
				supporter_id: "",
				status: false
			});
		}
	};

	render() {
		return (
			<div>
				<div className='page-content page-container' id='page-content'>
					<div className='padding'>
						<div className='row'>
							<div className='col-md-8'>
								<div className='card'>
									<div className='card-header'>
										<strong>Thêm môn học mới</strong>
									</div>
									<div className='card-body'>
										<div className='form-group row'>
											<label className='col-sm-4 col-form-label'>
												Tên môn học
											</label>
											<div className='col-sm-8'>
												<input
													type='text'
													className='form-control'
													name='name'
													onChange={this._onChange}
													value={this.state.name}
													ref={(input) =>
														(this.nameInput = input)
													}
												/>
											</div>
										</div>
										<div className='form-group row'>
											<label className='col-sm-4 col-form-label'>
												Mã môn học
											</label>
											<div className='col-sm-8'>
												<input
													type='text'
													className='form-control'
													name='code'
													onChange={this._onChange}
													value={this.state.code}
													ref={(input) =>
														(this.codeInput = input)
													}
												/>
											</div>
										</div>
										<div className='form-group row'>
											<label className='col-sm-4 col-form-label'>
												Link Messenger FB
											</label>
											<div className='col-sm-8'>
												<input
													type='text'
													className='form-control'
													name='support_fb_link'
													onChange={this._onChange}
													value={
														this.state
															.support_fb_link
													}
													ref={(input) =>
														(this.supportFbLink = input)
													}
												/>
											</div>
										</div>

										<div className='form-group row'>
											<label className='col-sm-4 col-form-label'>
												Giáo viên
											</label>
											<div className='col-sm-8'>
												<select
													name='teacher_id'
													className='custom-select'
													onChange={this._onChange}
													value={
														this.state.teacher_id
													}
												>
													<option value=''>
														-- Chọn giáo viên --
													</option>
													{this.fetchRows('TEACHER')}
												</select>
											</div>
										</div>

										<div className='form-group row'>
											<label className='col-sm-4 col-form-label'>
												Trơ giảng
											</label>
											<div className='col-sm-8'>
												<select
													name='supporter_id'
													className='custom-select'
													onChange={this._onChange}
													value={
														this.state.supporter_id
													}
												>
													<option value=''>
														-- Chọn trợ giảng --
													</option>
													{this.fetchRows('SUPPORTER')}
												</select>
											</div>
										</div>
										<div className="form-group row">
											<label className="col-sm-4 col-form-label">Thứ tự</label>
											<div className="col-sm-8">
												<input
													type="number"
													className="form-control"
													name="ordering"
													onChange={this._onChange}
													value={this.state.ordering}
												/>
											</div>
										</div>
										<div className='form-group row'>
											<label className='col-sm-4 col-form-label'>
												Hình thức học
											</label>
											<div className='col-sm-8'>
												<Radio.Group
													onChange={this._onChange}
													name='is_online'
													value={this.state.is_online}
												>
													<Radio value={false}>
														Offline
													</Radio>
													<Radio value={true}>
														Online
													</Radio>
												</Radio.Group>
											</div>
										</div>

										<div className='form-group row'>
											<label className='col-sm-4 col-form-label'>
												Hiển thị
											</label>
											<div className='col-sm-8'>
												<Radio.Group
													onChange={this._onChange}
													name='status'
													value={this.state.status}
												>
													<Radio value={false}>
														Ẩn
													</Radio>
													<Radio value={true}>
														Hiện
													</Radio>
												</Radio.Group>
											</div>
										</div>

										<div className='form-group row'>
											<div className='col-sm-12 text-right'>
												<button
													className='btn btn-primary mt-2'
													onClick={this.handleSubmit}
												>
													Lưu
												</button>
												<button
													className='btn btn-primary mt-2 ml-2'
													onClick={this.handleSave}
												>
													Lưu & Thêm mới
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		subjects: state.subject.subjects,
		redirect: state.subject.redirect,
		token: state.auth.token,
		students: state.student.students,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ createSubject, listAdmin }, dispatch);
}

let subjectCreateContainer = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(SubjectCreate)
);

export default subjectCreateContainer;
