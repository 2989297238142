export const LIST_CHAPTER = 'LIST_CHAPTER';
export const CREATE_CHAPTER = 'CREATE_CHAPTER';
export const SHOW_CHAPTER = 'SHOW_CHAPTER';
export const UPDATE_CHAPTER = 'UPDATE_CHAPTER';
export const DELETE_CHAPTER = 'DELETE_CHAPTER';
export const COPY_CHAPTER = 'COPY_CHAPTER';
export const ADD_DELETE = 'ADD_DELETE';
export const CHECK_ALL = 'CHECK_ALL';
export const LIST_CATEGORY = 'LIST_CATEGORY';
export const UPDATE_META_DATA = 'UPDATE_META_DATA';
export const LIST_CHAPTER_BY_CLASSROOM = 'LIST_CHAPTER_BY_CLASSROOM';