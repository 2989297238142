import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { listSubject } from "../../redux/subject/action";
import { listAdmin } from "../../redux/student/action";
import { listBookCategory, createBook } from "../../redux/book/action";
import { uploadImage } from "../../redux/category/action";
import { Editor } from "@tinymce/tinymce-react";
import { DatePicker } from "antd";
import baseHelpers from "../../helpers/BaseHelpers";
import moment from "moment";
import ModalAttachedClassroom from "./ModalAttachedClassroom";
import ModalClassroomRelate from "../classroom/Components/ModalClassroomRelate";
import ModalBookRelate from "./ModalBookRelate";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

let CDN_LINK = 'https://cdn.luyenthitiendat.vn/';

class BookCreate extends Component {
  constructor(props) {
    super();
    this.state = {
      name: "",
      subject_id: "",
      category_id: "",
      description: "",
      content: "",
      status: true,
      is_featured: false,
      price: "",
      code: "",
      origin_price: "",
      files: [],
      external_link: "",
      ordering: 1,
      teacher_id: null,
      quantity: 0,
      promotion: {
        from_date: null,
        to_date: null,
        type: "BY_DATE_RANGE",
        hour: 0
      },
      listClassroomAttached: [],
      listClassroomRelates: [],
      listBookRelates: [],
      avtPreview: "",
      level: null
    };
  }

  async componentDidMount() {
    const data = {
      limit: 999,
      is_delete: false,
    };
    await this.props.listSubject(data);
    await this.props.listBookCategory(data);

    const params = {
      user_group: "TEACHER",
      limit: 100,
    };
    await this.props.listAdmin(params);
  }

  _onChange = async (e) => {
    var name = e.target.name;
    let value = e.target.value;
    let checked = e.target.checked;
    let avtPreview = "";

    if (name === "is_featured" || name === "status") {
      value = checked;
    }

    if (name === "files") {

      value = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = () => {
          avtPreview = reader.result;
          resolve(reader.result);
        };
        reader.onerror = (error) => reject(error);
      });
      value = [value];
      this.setState({
        [name]: value,
        avtPreview: avtPreview
      });
    } else {
      this.setState({
        [name]: value,
      });
    }
  };

  _onChangeTypePromotion = async (e) => {

    let { name, value } = e.target;

    this.setState({
      promotion: {
        ...this.state.promotion,
        [name]: value
      }
    })


  }

  handleSubmit = async (type) => {
    let { promotion, listClassroomAttached, listClassroomRelates, listBookRelates } = this.state;
    const data = {
      name: this.state.name,
      subject_id: this.state.subject_id,
      category_id: this.state.category_id,
      is_featured: this.state.is_featured,
      status: this.state.status,
      files: this.state.files,
      external_link: this.state.external_link,
      price: isNaN(this.state.price) ? 0 : this.state.price,
      origin_price: isNaN(this.state.origin_price)
        ? 0
        : this.state.origin_price,
      ordering: this.state.ordering,
      content: this.state.content,
      description: this.state.description,
      teacher_id: this.state.teacher_id,
      code: this.state.code,
      classroom_attached: listClassroomAttached.map(item => item.id),
      classroom_relates: listClassroomRelates.map(item => item.id),
      book_relates: listBookRelates.map(item => item.id),
      quantity: this.state.quantity,
      level: this.state.level,
      // promotion: this.state.promotion.to_date && this.state.promotion.from_date ? this.state.promotion : {}
    };

    // if (promotion.to_date || promotion.from_date) {
    data.promotion = promotion;
    // }

    if (type === 0) {
      console.log("chưa làm");
    } else {
      await this.props.createBook(data);
      if (this.props.redirect === true && this.props.book) {
        await this.props.history.push("/book");
      }
    }
  };

  fetchSubjectRows() {
    if (this.props.subjects instanceof Array) {
      return this.props.subjects.map((obj, i) => {
        return <option key={i} value={obj._id}>{obj.name}</option>;
      });
    }
  }

  fetchCategoryRows() {
    if (this.props.bookCategories instanceof Array) {
      return this.props.bookCategories.map((obj, i) => {
        return <option key={i} value={obj._id}>{obj.name}</option>;
      });
    }
  }

  _handleEditorContentChange = (value, editor) => {
    this.setState({ content: value });
  };
  _handleEditorDescriptionChange = (value, editor) => {
    this.setState({ description: value });
  };

  _uploadImageCallBack = async (blobInfo, success, failure) => {
    let file = blobInfo.blob();
    const data = new FormData();
    data.append("files", file);

    await this.props.uploadImage(data);

    if (this.props.image != null) {
      success(this.props.image);
    } else {
      failure("Upload image fail");
    }
  };

  fetchTeacherRows() {
    if (this.props.students instanceof Array) {
      return this.props.students.map((obj, i) => {
        return (
          <option value={obj._id} key={obj._id.toString()}>
            {obj.fullname}
          </option>
        );
      });
    }
  }

  changeDateStart = (date, dateString) => {
    if (date !== null) {
      this.setState({
        promotion: {
          ...this.state.promotion,
          from_date: date.format("YYYY/MM/DD HH:mm"),
        }
      });
    } else {
      this.setState({
        promotion: {
          ...this.state.promotion,
          from_date: null,
        }
      });
    }
  };

  changeDateEnd = (date, dateString) => {
    if (date !== null) {
      this.setState({
        promotion: {
          ...this.state.promotion,
          to_date: date.format("YYYY/MM/DD HH:mm"),
        }
      });
    } else {
      this.setState({
        promotion: {
          ...this.state.promotion,
          to_date: null,
        }
      });
    }
  };

  handleAddClassroomAttached = async (data) => {
    let dataList = [...this.state.listClassroomAttached];
    if (data) {
      dataList.push(data);
    }


    await this.setState({
      listClassroomAttached: dataList
    })

  }

  handleAddClassroomRelate = async (data) => {
    let dataList = [...this.state.listClassroomRelates];
    if (data) {
      dataList.push(data);
    }


    await this.setState({
      listClassroomRelates: dataList
    })

  }

  handleAddBookRelate = async (data) => {
    let dataList = [...this.state.listBookRelates];
    if (data) {
      dataList.push(data);
    }


    await this.setState({
      listBookRelates: dataList
    })

  }

  handleRemoveClassroomAttached = async (item) => {
    let dataRemove = [];
    let { listClassroomAttached } = this.state;



    if (listClassroomAttached) {
      dataRemove = listClassroomAttached.filter(value => value.id !== item.id);
    }

    this.setState({
      listClassroomAttached: dataRemove
    })
  }

  handleRemoveClassroomRelate = async (item) => {
    let dataRemove = [];
    let { listClassroomRelates } = this.state;



    if (listClassroomRelates) {
      dataRemove = listClassroomRelates.filter(value => value.id !== item.id);
    }

    this.setState({
      listClassroomRelates: dataRemove
    })
  }

  handleRemoveBookRelate = async (item) => {
    let dataRemove = [];
    let { listBookRelates } = this.state;



    if (listBookRelates) {
      dataRemove = listBookRelates.filter(value => value.id !== item.id);
    }

    this.setState({
      listBookRelates: dataRemove
    })
  }

  handleUploadImage = () => {
    document.getElementById("input-upload-image").click();
  }

  remoAvatar = () => {
    document.getElementById("input-upload-image").value = "";
    this.setState({
      files: [],
      avtPreview: ""
    })
  }

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  onDragEndClassroomAttached = async (result) => {
    // const { listClassroomAttached } = this.state;

    // // dropped outside the list
    // if (
    //   !result.destination ||
    //   result.source.index == result.destination.index
    // ) {
    //   return;
    // }
    // this.setState(
    //   {
    //     listClassroomAttached: produce(listClassroomAttached, (draft) => {
    //       const tg = { ...draft[result.source.index] };
    //       draft[result.source.index] = { ...draft[result.destination.index] };
    //       draft[result.destination.index] = tg;
    //     }),
    //   }
    // );
    if (!result.destination) {
      return;
    }

    const items = this.reorder(
      this.state.listClassroomAttached,
      result.source.index,
      result.destination.index
    );

    await this.setState({
      listClassroomAttached: items,
    });
  }

  onDragEndClassroomRelate = async (result) => {
    if (!result.destination) {
      return;
    }

    const items = this.reorder(
      this.state.listClassroomRelates,
      result.source.index,
      result.destination.index
    );

    await this.setState({
      listClassroomRelates: items,
    });
  }

  onDragEndBookRelate = async (result) => {
    if (!result.destination) {
      return;
    }

    const items = this.reorder(
      this.state.listBookRelates,
      result.source.index,
      result.destination.index
    );

    await this.setState({
      listBookRelates: items,
    });
  }

  clearFormDate = async => {
    console.log(123);
  }

  render() {
    const { promotion, origin_price, price } = this.state;


    let discountPercent = ((price - origin_price) / origin_price * 100).toFixed(0);
    return (
      <div>
        <div className="page-content page-container page-create-book" id="page-content">
          <div className="padding">
            <h2 className='text-md text-highlight sss-page-title'>
              Thêm sách mới
            </h2>
            <div className="general-info">
              <h3 className="title-block">Thông tin chung</h3>
              <div className="content">
                <input
                  onChange={this._onChange}
                  type="file"
                  className="form-control-file d-none"
                  name="files"
                  id="input-upload-image"
                />
                <div className="block-image">
                  {
                    !this.state.files || this.state.files.length == 0
                      ?
                      <button type="button" onClick={this.handleUploadImage}>
                        <img src="/assets/img/icon-upload-file.svg" className="mr-10" alt="" />
                        <span>THÊM HÌNH</span>
                      </button>
                      :
                      <div className="block-image-overlay">
                        <img
                          id="output"
                          src={this.state.avtPreview}
                          alt="your image"
                          className="image"
                        />
                        <div className="middle">
                          <div className="text" onClick={this.remoAvatar}>Hủy chọn</div>
                        </div>
                      </div>
                  }
                </div>
                <div className="block-content">
                  <div className="item-input-text">
                    <div className="form-group mr-16" style={{ width: "144px" }}>
                      <label className="text-form-label">Mã sách</label>
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          name="code"
                          onChange={this._onChange}
                          value={this.state.code}
                        />
                      </div>
                    </div>

                    <div className="form-group mr-16" style={{ width: "60%" }}>
                      <label className="text-form-label">Tên sản phẩm</label>
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          onChange={this._onChange}
                          value={this.state.name}
                        />
                      </div>
                    </div>
                    <div className="form-group" style={{ width: "40%" }}>
                      <label className="text-form-label">Link bản xem thử</label>
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          name="external_link"
                          onChange={this._onChange}
                          value={this.state.external_link}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="item-input-text">
                    <div className="form-group mb-0 mr-32">
                      <label className="text-form-label">Phân loại</label>
                      <div>
                        <select
                          className="custom-select"
                          value={this.state.level}
                          name="level"
                          onChange={this._onChange}
                        >
                          <option value="">Cấp học</option>
                          <option value="1">Lớp 1</option>
                          <option value="2">Lớp 2</option>
                          <option value="3">Lớp 3</option>
                          <option value="4">Lớp 4</option>
                          <option value="5">Lớp 5</option>
                          <option value="6">Lớp 6</option>
                          <option value="7">Lớp 7</option>
                          <option value="8">Lớp 8</option>
                          <option value="9">Lớp 9</option>
                          <option value="10">Lớp 10</option>
                          <option value="11">Lớp 11</option>
                          <option value="12">Lớp 12</option>
                        </select>
                      </div>
                    </div>

                    <div className="form-group mr-32 mb-0">
                      <label className="text-form-label">Môn học</label>
                      <div>
                        <select
                          className="custom-select"
                          value={this.state.subject_id}
                          name="subject_id"
                          onChange={this._onChange}
                        >
                          <option value="">-- Chọn môn học --</option>
                          {this.fetchSubjectRows()}
                        </select>
                      </div>
                    </div>

                    <div className="form-group mb-0 mr-32">
                      <label className="text-form-label">Danh mục sách</label>
                      <div>
                        <div className="">
                          <select
                            className="custom-select"
                            value={this.state.category_id}
                            name="category_id"
                            onChange={this._onChange}
                          >
                            <option value="">-- Chọn danh mục --</option>
                            {this.fetchCategoryRows()}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="form-group mb-0 mr-32">
                      <label className="text-form-label">Giáo viên</label>
                      <div>
                        <select
                          className="custom-select"
                          value={this.state.teacher_id}
                          name="teacher_id"
                          onChange={this._onChange}
                        >
                          <option value="">-- Chọn giáo viên --</option>
                          {this.fetchTeacherRows()}
                        </select>
                      </div>
                    </div>

                    <div className="form-group mb-0 mr-32">
                      <label className="text-form-label">Nổi bật</label>
                      <div className="mt-16">
                        <div className="float-left">
                          <label className="ui-switch ui-switch-md info m-t-xs">
                            <input
                              type="checkbox"
                              name="is_featured"
                              value={this.state.is_featured}
                              checked={this.state.is_featured === true ? 'checked' : ''}
                              onChange={this._onChange}
                            />{' '}
                            <i />
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="form-group mb-0 mr-32">
                      <label className="text-form-label">Hiển thị</label>
                      <div className="mt-16">
                        <div className="float-left">
                          <label className="ui-switch ui-switch-md info m-t-xs">
                            <input
                              type="checkbox"
                              name="status"
                              value={this.state.status}
                              checked={this.state.status === true ? 'checked' : ''}
                              onChange={this._onChange}
                            />{' '}
                            <i />
                          </label>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div className="block-price-discount">
              <h3 className="title-block">Giá và khuyến mãi</h3>
              <div className="content input-group">
                <div className="form-group mb-0 mr-32" style={{ width: "180px" }}>
                  <label className="text-form-label">Giá sản phẩm</label>
                  <div>
                    <input
                      type="number"
                      className="form-control"
                      name="origin_price"
                      onChange={this._onChange}
                      value={this.state.origin_price}
                    />
                  </div>
                </div>
                <div className="form-group mb-0 mr-32" style={{ width: "180px" }}>
                  <label className="text-form-label">Giá khuyến mãi</label>
                  <div>
                    <input
                      type="number"
                      className="form-control"
                      name="price"
                      onChange={this._onChange}
                      value={this.state.price}
                    />
                  </div>
                </div>
                <div className="form-group mb-0 mr-32" style={{ width: "160px" }}>
                  <label className="text-form-label">Chênh lệch</label>
                  <div className="percent-difference">
                    <span> {discountPercent && !isNaN(discountPercent) ? discountPercent : 0}%</span>
                  </div>
                </div>


                <div className="form-group mb-0 mr-32">
                  <label className="text-form-label">Chọn thời gian khuyến mãi</label>
                  <div>
                    <select
                      className="custom-select"
                      value={promotion.type}
                      name="type"
                      onChange={this._onChangeTypePromotion}
                    >
                      {/* <option value="">Chọn thời gian</option> */}
                      <option value="BY_DATE_RANGE">Khoảng thời gian cụ thể</option>
                      <option value="BY_HOUR">Thời gian kết thúc</option>
                    </select>
                  </div>
                </div>

                {
                  promotion.type == "BY_DATE_RANGE"
                  &&
                  <div className="form-group mb-0 mr-32">
                    <label className="text-form-label">Nhập thời gian</label>
                    <div className="group-date" style={{ display: "flex" }}>
                      <DatePicker
                        format={
                          "DD/MM/YYYY HH:mm"
                        }
                        value={promotion.from_date
                          ? moment(promotion.from_date)
                          : null}
                        showTime={{ format: 'HH:mm' }}
                        placeholder="Từ ngày"
                        // allowClear={<div onClick={this.clearFormDate}> </div>}
                        onChange={this.changeDateStart}
                      // onOk={this.clearFormDate}
                      />
                      <DatePicker
                        format={
                          "DD/MM/YYYY HH:mm"
                        }
                        value={promotion.to_date
                          ? moment(promotion.to_date)
                          : null}
                        showTime={{ format: 'HH:mm' }}
                        placeholder="Đến ngày"
                        onChange={this.changeDateEnd}
                        className="ml-2"
                      />
                    </div>
                  </div>
                }
                {
                  promotion.type == "BY_HOUR"
                  &&
                  <div className="form-group mb-0 mr-32" style={{ width: "180px" }}>
                    <label className="text-form-label">Nhập số giờ</label>
                    <div>
                      <input
                        type="number"
                        className="form-control"
                        name="hour"
                        onChange={this._onChangeTypePromotion}
                        value={promotion.hour}
                      />
                    </div>
                  </div>
                }
                <div className="form-group mb-0 mr-32" style={{ width: "180px" }}>
                  <label className="text-form-label">Số lượng khuyến mãi</label>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      name="quantity"
                      onChange={this._onChange}
                      value={this.state.quantity}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="block-attach-product">
              <div className="title-action">
                <h3 className="title-block mb-0 mr-18">Khóa học đi kèm</h3>
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#classroom-attached"
                  data-toggle-class="fade-down"
                  data-toggle-class-target=".animate"
                >
                  Thêm khóa Học
                  <img src="/assets/img/icon-add.svg" alt="" className="ml-12" />
                </button>
              </div>

              <div className="block-list-product">
                <DragDropContext onDragEnd={this.onDragEndClassroomAttached}>
                  <Droppable droppableId="droppable" direction="horizontal">
                    {(provided, snapshot) => (
                      <ul
                        className="list-products ml-0 pl-0"
                        ref={provided.innerRef}
                        style={{
                          background: snapshot.isDragging ? "#e8f0fe" : "none",
                        }}
                      >
                        {
                          this.state.listClassroomAttached && this.state.listClassroomAttached.length > 0
                          &&
                          this.state.listClassroomAttached.map((item, index) => {
                            return (
                              <Draggable
                                key={index}
                                draggableId={"" + index}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <li
                                    className="list-item"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      ...provided.draggableProps.style,
                                      userSelect: "none",
                                      background: snapshot.isDragging
                                        ? "#e8f0fe"
                                        : "none",
                                      display: "table-row",
                                    }}
                                  >
                                    <div className="block-content">
                                      <div className="action-head">
                                        <a className="mr-14">
                                          <img src="/assets/img/icon-move.svg" alt="" />
                                        </a>
                                        <a onClick={() => this.handleRemoveClassroomAttached(item)}>
                                          <img src="/assets/img/icon-close.svg" alt="" />
                                        </a>
                                      </div>
                                      <div className="product-info">
                                        <div className="image">
                                          {
                                            item.avatar
                                              ?
                                              <img src={item.avatar} alt="" />
                                              :
                                              <img src="/assets/img/no-image.png" alt="" />
                                          }
                                        </div>
                                        <p className="name">
                                          {item.name ? item.name : ""}
                                        </p>
                                        <span className="price">{item.price ? baseHelpers.currencyFormat(item.price) : 0} đ</span>
                                      </div>
                                    </div>
                                  </li>
                                )}
                              </Draggable>
                            )
                          })
                        }
                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>

            <div className="block-attach-product">
              <div className="title-action">
                <h3 className="title-block mb-0 mr-18">Khóa học đề xuất</h3>
                <button
                  className="button"
                  data-toggle="modal"
                  data-target="#classroom-relate"
                  data-toggle-class="fade-down"
                  data-toggle-class-target=".animate"
                >
                  Thêm khóa Học
                  <img src="/assets/img/icon-add.svg" alt="" className="ml-12" />
                </button>
              </div>

              <div className="block-list-product">
                <DragDropContext onDragEnd={this.onDragEndClassroomRelate}>
                  <Droppable droppableId="droppable" direction="horizontal">
                    {(provided, snapshot) => (
                      <ul
                        className="list-products ml-0 pl-0"
                        ref={provided.innerRef}
                        style={{
                          background: snapshot.isDragging ? "#e8f0fe" : "none",
                        }}
                      >
                        {
                          this.state.listClassroomRelates && this.state.listClassroomRelates.length > 0
                          &&
                          this.state.listClassroomRelates.map((item, index) => {
                            return (
                              <Draggable
                                key={index}
                                draggableId={"" + index}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <li
                                    className="list-item"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      ...provided.draggableProps.style,
                                      userSelect: "none",
                                      background: snapshot.isDragging
                                        ? "#e8f0fe"
                                        : "none",
                                      display: "table-row",
                                    }}
                                  >
                                    <div className="block-content">
                                      <div className="action-head">
                                        <a className="mr-14">
                                          <img src="/assets/img/icon-move.svg" alt="" />
                                        </a>
                                        <a onClick={() => this.handleRemoveClassroomRelate(item)}>
                                          <img src="/assets/img/icon-close.svg" alt="" />
                                        </a>
                                      </div>
                                      <div className="product-info">
                                        <div className="image">
                                          {
                                            item.avatar
                                              ?
                                              <img src={item.avatar} alt="" />
                                              :
                                              <img src="/assets/img/no-image.png" alt="" />
                                          }
                                        </div>
                                        <p className="name">
                                          {item.name ? item.name : ""}
                                        </p>
                                        <span className="price">{item.price ? baseHelpers.currencyFormat(item.price) : 0} đ</span>
                                      </div>
                                    </div>
                                  </li>
                                )}
                              </Draggable>

                            )
                          })
                        }
                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>

            <div className="block-attach-product">
              <div className="title-action">
                <h3 className="title-block mb-0 mr-18">Sách đề xuất</h3>
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#book-relate"
                  data-toggle-class="fade-down"
                  data-toggle-class-target=".animate"
                >
                  Thêm sách
                  <img src="/assets/img/icon-add.svg" alt="" className="ml-12" />
                </button>
              </div>

              <div className="block-list-product">
                <DragDropContext onDragEnd={this.onDragEndBookRelate}>
                  <Droppable droppableId="droppable" direction="horizontal">
                    {(provided, snapshot) => (
                      <ul
                        className="list-products ml-0 pl-0"
                        ref={provided.innerRef}
                        style={{
                          background: snapshot.isDragging ? "#e8f0fe" : "none",
                        }}
                      >
                        {
                          this.state.listBookRelates && this.state.listBookRelates.length > 0
                          &&
                          this.state.listBookRelates.map((item, index) => {
                            return (
                              <Draggable
                                key={index}
                                draggableId={"" + index}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <li
                                    className="list-item"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      ...provided.draggableProps.style,
                                      userSelect: "none",
                                      background: snapshot.isDragging
                                        ? "#e8f0fe"
                                        : "none",
                                      display: "table-row",
                                    }}
                                  >
                                    <div className="block-content">
                                      <div className="action-head">
                                        <a className="mr-14">
                                          <img src="/assets/img/icon-move.svg" alt="" />
                                        </a>
                                        <a onClick={() => this.handleRemoveBookRelate(item)}>
                                          <img src="/assets/img/icon-close.svg" alt="" />
                                        </a>
                                      </div>
                                      <div className="product-info">
                                        <div className="image">
                                          {
                                            item.avatar
                                              ?
                                              <img src={item.avatar} alt="" />
                                              :
                                              <img src="/assets/img/no-image.png" alt="" />
                                          }
                                        </div>
                                        <p className="name">
                                          {item.name ? item.name : ""}
                                        </p>
                                        <span className="price">{item.price ? baseHelpers.currencyFormat(item.price) : 0} đ</span>
                                      </div>
                                    </div>
                                  </li>
                                )}
                              </Draggable>
                            )
                          })
                        }
                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>

            <div className="block-description">
              <h3 className="title-block">Mô tả sản phẩm</h3>
              <div className="content">
                <Editor
                apiKey={process.env.REACT_APP_API_KEY_TINY}
                  onInit={(evt, editor) => {
                    this._handleEditorDescriptionChange(
                      this.state.description,
                      editor
                    );
                  }}
                  value={this.state.description}
                  init={{
                    height: 500,
                    menubar: false,
                    images_file_types:
                      "jpeg,jpg,jpe,jfi,jif,jfif,png,gif,bmp,webp",
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount tiny_mce_wiris",
                    ],
                    external_plugins: {
                      tiny_mce_wiris:
                        "https://www.wiris.net/demo/plugins/tiny_mce/plugin.js",
                    },
                    toolbar:
                      "undo redo | formatselect | " +
                      "bold italic backcolor | image | alignleft aligncenter " +
                      "alignright alignjustify | bullist numlist outdent indent | fontselect |  fontsizeselect |" +
                      "searchreplace visualblocks code fullscreen | " +
                      "lists link advlist insertdatetime media | tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | table paste code removeformat | help",
                    fontsize_formats:
                      "8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt",
                    content_style:
                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    draggable_modal: true,
                    htmlAllowedTags: [".*"],
                    htmlAllowedAttrs: [".*"],
                    images_upload_handler: (
                      blobInfo,
                      success,
                      failure
                    ) =>
                      this._uploadImageCallBack(
                        blobInfo,
                        success,
                        failure
                      ),
                  }}
                  onEditorChange={
                    this._handleEditorDescriptionChange
                  }
                />
              </div>
            </div>
            <div className="block-editor-content">
              <h3 className="title-block">Nội dung</h3>
              <div className="content">
                <Editor
                apiKey={process.env.REACT_APP_API_KEY_TINY}
                  onInit={(evt, editor) => {
                    this._handleEditorContentChange(
                      this.state.content,
                      editor
                    );
                  }}
                  value={this.state.content}
                  init={{
                    height: 500,
                    menubar: false,
                    images_file_types:
                      "jpeg,jpg,jpe,jfi,jif,jfif,png,gif,bmp,webp",
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount tiny_mce_wiris",
                    ],
                    external_plugins: {
                      tiny_mce_wiris:
                        "https://www.wiris.net/demo/plugins/tiny_mce/plugin.js",
                    },
                    toolbar:
                      "undo redo | formatselect | " +
                      "bold italic backcolor | image | alignleft aligncenter " +
                      "alignright alignjustify | bullist numlist outdent indent | fontselect |  fontsizeselect |" +
                      "searchreplace visualblocks code fullscreen | " +
                      "lists link advlist insertdatetime media | tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | table paste code removeformat | help",
                    fontsize_formats:
                      "8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt",
                    content_style:
                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    draggable_modal: true,
                    htmlAllowedTags: [".*"],
                    htmlAllowedAttrs: [".*"],
                    images_upload_handler: (
                      blobInfo,
                      success,
                      failure
                    ) =>
                      this._uploadImageCallBack(
                        blobInfo,
                        success,
                        failure
                      ),
                  }}
                  onEditorChange={this._handleEditorContentChange}
                />
              </div>
            </div>
            <div className="block-action-footer">
              <button type="button" className="btn-cancel" onClick={() => this.props.history.push("/book")} >
                <img src="/assets/img/icon-arrow-left.svg" alt="" className="mr-14" />
                Hủy
              </button>
              <button type="button" className="btn-submit ml-16" onClick={() => this.handleSubmit(1)}>
                Tạo mới
                <img src="/assets/img/icon-arrow-right.svg" alt="" className="ml-14" />
              </button>
            </div>
            <ModalAttachedClassroom book={this.props.book} handleAddClassroom={this.handleAddClassroomAttached} selectedClassroom={this.state.listClassroomAttached} classroomAttached={this.props.classroomAttached} />
            <ModalClassroomRelate book={this.props.book} handleAddClassroom={this.handleAddClassroomRelate} selectedClassroom={this.state.listClassroomRelates} classroomRelates={this.props.classroomRelates} />
            <ModalBookRelate book={this.props.book} handleAddBook={this.handleAddBookRelate} selectedBooks={this.state.listBookRelates} bookRelates={this.props.bookRelates} />
          </div>
        </div>
      </div >
    );
  }
}

function mapStateToProps(state) {
  return {
    subjects: state.subject.subjects,
    book: state.book.book,
    bookCategories: state.book.bookCategories,
    redirect: state.book.redirect,
    image: state.question.image,
    students: state.student.students,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { listSubject, createBook, listBookCategory, uploadImage, listAdmin },
    dispatch
  );
}

let ContainerCreate = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BookCreate)
);

export default ContainerCreate;
