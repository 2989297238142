export const LIST_CLASSROOM = 'LIST_CLASSROOM';
export const CREATE_CLASSROOM = 'CREATE_CLASSROOM';
export const SHOW_CLASSROOM = 'SHOW_CLASSROOM';
export const UPDATE_CLASSROOM = 'UPDATE_CLASSROOM';
export const DELETE_CLASSROOM = 'DELETE_CLASSROOM';
export const DATA_REMOVE_CLASS = 'DATA_REMOVE_CLASS';
export const ADD_DELETE = 'ADD_DELETE';
export const CHECK_ALL = 'CHECK_ALL';
export const LIST_CODE = 'LIST_CODE';
export const CREATE_CODE = 'CREATE_CODE';
export const CLASSROOM_REPORT = 'CLASSROOM_REPORT';
export const CLASSROOM_MEMBER = 'CLASSROOM_MEMBER';
export const ADD_MEMBER = 'ADD_MEMBER';
export const REMOVE_MEMBER = 'REMOVE_MEMBER';
export const DATA_REMOVE_MEMBER = 'DATA_REMOVE_MEMBER';
export const DATA_ADD_MEMBER = 'DATA_ADD_MEMBER';
export const EXPORT_CODE = 'EXPORT_CODE';
export const LIST_CLASSROOM_PER_USER = 'LIST_CLASSROOM_PER_USER';
export const RESET_BILL_CREATE_STATE = 'RESET_BILL_CREATE_STATE';
export const UPDATE_LESSON = 'UPDATE_LESSON';
export const CHECK_DILIGENCE = 'CHECK_DILIGENCE';
export const DIFF_BUOIHOC = 'DIFF_BUOIHOC';
export const DELETE_CLASSROOM_CODE = 'DELETE_CLASSROOM_CODE';
export const LIST_CHAPTER_CATEGORY = 'LIST_CHAPTER_CATEGORY';
export const ADD_CHAPTER = 'ADD_CHAPTER';
export const REMOVE_CHAPTER = 'REMOVE_CHAPTER';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const REMOVE_CATEGORY = 'REMOVE_CATEGORY';
export const UPDATE_POSITION = 'UPDATE_POSITION';
export const SET_VIDEO_WATCH_TIME = 'SET_VIDEO_WATCH_TIME';
export const CHECK_INPUT_ITEM = 'CHECK_INPUT_ITEM';
export const CLASSROOM_UPDATE_RELATE = 'CLASSROOM_UPDATE_RELATE';
export const UPDATE_META_DATA = 'UPDATE_META_DATA';
export const CHECK_CLASSROOM_ATTEND = 'CHECK_CLASSROOM_ATTEND';