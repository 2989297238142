import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Select, Checkbox } from "antd";
import { Editor } from "@tinymce/tinymce-react";
import { setLoader } from "../LoadingContext";

// const { Option } = Select;

class ModalQuestion5 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questionNo: 1,
      type: "TN_MULTI_CHOICE",
      question: null,
      selectedAnswers: [],
      answer_content: null,
      doc_link: "",
      video_link: "",
      selectedFile: null,
      content: "",
      content1: "",
      uploadedImages: [],
      actionQuestion: "create",
      currentQuestionvalue: null,
    };
  }

  componentDidUpdate = async (prevProps, prevState) => {
    if (prevProps.questionNo !== this.props.questionNo) {
      this.setState({ questionNo: this.props.questionNo });
    }
    if (prevProps.actionQuestion !== this.props.actionQuestion) {
      this.setState({ actionQuestion: this.props.actionQuestion });
    }
    if (prevProps.currentQuestionvalue !== this.props.currentQuestionvalue) {
      const { answer, answer_content, doc_link, video_link, question_no } =
      this.props.currentQuestionvalue || {};
      let selectedAnswers = [];
      if (typeof answer === "string") {
        selectedAnswers = answer.split(",").map((item) => item.trim());
      }

      this.setState({
        currentQuestionvalue: this.props.currentQuestionvalue,
        selectedAnswers,
        answer_content,
        doc_link,
        video_link,
        questionNo: question_no,
      });
    }
  };

  _onChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  _onChangeCheckBoxG = (e) => {
    const { value } = e.target;
    this.setState((prevState) => {
      const selectedAnswers = prevState.selectedAnswers.includes(value)
        ? prevState.selectedAnswers.filter((answer) => answer !== value)
        : [...prevState.selectedAnswers, value];

      return { selectedAnswers };
    });
  };

  handleSave = async (e) => {
    setLoader(true);
    e.preventDefault();
    const { examId, examSectionId, examSectionGroupId, examSectionSubjectId } =
      this.props;

    const question = {
      exam_id: examId,
      exam_section_id: examSectionId === "" ? null : examSectionId,
      exam_section_group_id: examSectionGroupId === "" ? null : examSectionGroupId,
      subject_id: examSectionSubjectId === "" ? null : examSectionSubjectId,
      answer: this.state.selectedAnswers.join(", "),
      answer_content: this.state.content1,
      type: this.state.type,
      doc_link: this.state.doc_link,
      video_link: this.state.video_link,
      question_no: this.state.questionNo,
    };

    if (this.state.actionQuestion === "create") {
      this.props.actionCreateQuestion(question);
    } else {
      question.question_id = this.state.currentQuestionvalue._id;
      this.props.actionUpdateQuestion(question);
    }
    this.closeModal();
    setLoader(false);
  };

  closeModal = () => {
    document.getElementById("close_create_5").click();
  };

  _handleEditorContentChange = (value, editor) => {
    this.setState({content: value});
  };
  _handleEditorContent1Change = (value, editor) => {
    this.setState({content1: value});
  };
  render() {
    const { selectedAnswers } = this.state;

    return (
      <div className="block-content">
        <div className="row">
          <div className="col-1 col-form-div">
            <div className="form-group">
              <label className="col-form-label">Câu</label>
            </div>
          </div>
          <div className="col-2 col-form-div">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                name="questionNo"
                onChange={this._onChange}
                value={this.state.questionNo}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-4 col-form-div">
            <div className="form-group">
              <label className="col-form-label">Đáp án</label>
            </div>
          </div>
          <div className="col-sm-8">
            <Checkbox
              onChange={this._onChangeCheckBoxG}
              name="answerA"
              value="A"
              checked={selectedAnswers.includes("A")}
            >
              A
            </Checkbox>
            <Checkbox
              onChange={this._onChangeCheckBoxG}
              name="answerB"
              value="B"
              checked={selectedAnswers.includes("B")}
            >
              B
            </Checkbox>
            <Checkbox
              onChange={this._onChangeCheckBoxG}
              name="answerC"
              value="C"
              checked={selectedAnswers.includes("C")}
            >
              C
            </Checkbox>
            <Checkbox
              onChange={this._onChangeCheckBoxG}
              name="answerD"
              value="D"
              checked={selectedAnswers.includes("D")}
            >
              D
            </Checkbox>
          </div>
        </div>
        {/* Additional Fields and Buttons */}
        <div className="row">
          <div className="col-sm-12 col-form-div">
            <div className="form-group">
              <label className="title-block">
                Lời giải
              </label>
              <div className="">
                <Editor
                  apiKey={process.env.REACT_APP_API_KEY_TINY}
                  onInit={(evt, editor) => {
                    this._handleEditorContent1Change(
                      this.state.content,
                      editor
                    );
                  }}
                  value={this.state.content1}
                  init={{
                    height: 300,
                    menubar: false,
                    images_file_types:
                      "jpeg,jpg,jpe,jfi,jif,jfif,png,gif,bmp,webp",
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount tiny_mce_wiris",
                    ],
                    external_plugins: {
                      tiny_mce_wiris:
                        "https://www.wiris.net/demo/plugins/tiny_mce/plugin.js",
                    },
                    toolbar:
                      "undo redo | formatselect | " +
                      "bold italic backcolor | image | alignleft aligncenter " +
                      "alignright alignjustify | bullist numlist outdent indent | fontselect |  fontsizeselect |" +
                      "searchreplace visualblocks code fullscreen | " +
                      "lists link advlist insertdatetime media | tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | table paste code removeformat | help",
                    fontsize_formats:
                      "8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt",
                    content_style:
                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    draggable_modal: true,
                    htmlAllowedTags: [".*"],
                    htmlAllowedAttrs: [".*"],
                    images_upload_handler: (
                      blobInfo,
                      success,
                      failure
                    ) =>
                      this._uploadImageCallBack(
                        blobInfo,
                        success,
                        failure
                      ),
                  }}
                  onEditorChange={this._handleEditorContent1Change}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12 col-form-div">
            <div className="form-group">
              <label className="col-form-label">
                Tài liệu tham khảo
              </label>
              <div className="">
                <input
                  type="text"
                  className="form-control"
                  name="doc_link"
                  onChange={this._onChange}
                  value={this.state.doc_link}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12 col-form-div">
            <div className="form-group">
              <label className="col-form-label">
                Video tham khảo
              </label>
              <div className="">
                <input
                  type="text"
                  className="form-control"
                  name="video_link"
                  onChange={this._onChange}
                  value={this.state.video_link}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="form-group row">
          <div className="col-sm-12 text-right">
            {this.props.actionQuestion === "update" && (
              <button
                name="reset"
                value="1"
                className="btn btn-primary mt-2 ml-2"
                onClick={this.handleSave}
              >
                Cập nhật
              </button>
            )}

            {this.props.actionQuestion === "create" && (
              <button
                name="reset"
                value="1"
                className="btn btn-primary mt-2 ml-2"
                onClick={this.handleSave}
              >
                Lưu & Thêm mới
              </button>
            )}
            <button
              id="close_create_5"
              className="btn btn-light mt-2 ml-2"
              data-dismiss="modal"
            >
              Bỏ qua
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ModalQuestion5)
);
